import React from "react";
import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";


const ViewAbhiruchi = ({ item }) => {

    const initialFormData = {
      abhiruchiType: item?.abhiruchiType?.type,
      name: item?.name,
      region: item?.region,
      description: item?.description,
  };

  const formFields = [
    { name: "abhiruchiType", label: "Abhiruchi Type", type: "text" },
    { name: "name", label: "Name", type: "text" },
    { name: "region", label: "Region", type: "text" },
    { name: "description", label: "Description", type: "text" },
  ];

  return (
    <ViewItem
      formFields={formFields}
      title="View Pandit Kyc"
      item={initialFormData}
    />
  );
};

export default ViewAbhiruchi;



