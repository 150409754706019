import React, { useEffect, useState } from "react";
import styles from "./panditProfileManagement.module.css";
import cardIcon from "../../assets/images/cardIcon.png";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { Spin } from "antd";
import { getConfig } from "../../utils/getConfig";
import { toast } from "sonner";
import useDebounce from "../../utils/useDebounce";
import axios from "axios";
import { handleExportDoc } from "../../utils/exportDoc";
import downArrow from '../../assets/images/navbar/dropdownWhite.png'

function PanditProfileManagement() {
  const [selectedOption, setSelectedOption] = useState("All");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [panditsData, setPanditsData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [cardData, setCardData] = useState("");
  let [thisMonthData, setThisMonthData] = useState(true);
  const [loading, setLoading] = useState(true);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [selectedOption, page, debouncedSearchQuery]);

  const fetchData = async () => {
    try {
      let url = `/panditProfileManagement/getAllPanditProfile?page=${page}&search=${debouncedSearchQuery}`;

      if (selectedOption !== "All") {
        url += `&status=${selectedOption}`;
      }

      const response = await userRequest.get(url);
      const responseCard = await userRequest.get(
        "/panditProfileManagement/getAdminPanditProfileCardData"
      );
      setCardData(responseCard.data);
      setPanditsData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const {
    totalPandits,
    totalPanditsWithKYCPending,
    totalPanditsWithVerified,
    thisMonth,
    previousMonth,
  } = cardData;
  const [isFilterVisible, setFilterVisible] = useState(false);

  const toggleFilterVisibility = () => {
    setFilterVisible(!isFilterVisible);
  };

  const handleExport = () => {
    handleExportDoc(
      `/panditProfileManagement/exportPanditProfileToExcel`,
      "PanditProfileManagement.xlsx"
    );
  };

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <p>Pandit Profile Management</p>
      </div>
      <div className={styles.cardMain}>
        <div className={styles.cardLeftMain}>
          <div className={styles.singleCardLeft}>
            <div className={styles.cardIcon}>
              <img src={cardIcon} alt="" />
            </div>
            <div className={styles.cardContent}>
              <p>{totalPandits || 0}</p>
              <p>Total Registered</p>
              <p>Pandit</p>
            </div>
          </div>
          <div className={styles.singleCardLeft}>
            <div className={styles.cardIcon}>
              <img src={cardIcon} alt="" />
            </div>
            <div className={styles.cardContent}>
              <p>{totalPanditsWithKYCPending || 0}</p>
              <p>Total Guest</p>
              <p>Pandit</p>
            </div>
          </div>
          <div className={styles.singleCardLeft}>
            <div className={styles.cardIcon}>
              <img src={cardIcon} alt="" />
            </div>
            <div className={styles.cardContent}>
              <p>{totalPanditsWithVerified || 0}</p>
              <p>Total Verified</p>
              <p>Pandit</p>
            </div>
          </div>
        </div>
        <div className={styles.cardRightMain}>
          <div className={styles.cardRightBox}>
            <div className={styles.cardRightHeader}>
              <p
                onClick={() => setThisMonthData(true)}
                style={{ color: thisMonthData ? "#ff9914" : "" }}
              >
                This Month
              </p>
              <p
                onClick={() => setThisMonthData(false)}
                style={{ color: !thisMonthData ? "#ff9914" : "" }}
              >
                Previous Month
              </p>
            </div>
            <div className={styles.cardRightContainer}>
              <div className={styles.singleCardRight}>
                <div className={styles.cardIconRight}>
                  <img src={cardIcon} alt="" />
                </div>
                <div className={styles.cardContentRight}>
                  <p>
                    {thisMonthData
                      ? thisMonth?.totalRegisteredPanditsThisMonth
                      : previousMonth?.totalRegisteredPanditsPreviousMonth}
                  </p>
                  <p>Registered Pandit</p>
                </div>
              </div>
              <div className={styles.singleCardRight}>
                <div className={styles.cardIconRight}>
                  <img src={cardIcon} alt="" />
                </div>
                <div className={styles.cardContentRight}>
                  <p>
                    {thisMonthData
                      ? thisMonth?.totalVerifiedPanditsThisMonth
                      : previousMonth?.totalVerifiedPanditsPreviousMonth}
                  </p>
                  <p>Verified Pandit</p>
                </div>
              </div>
              <div className={styles.singleCardRight}>
                <div className={styles.cardIconRight}>
                  <img src={cardIcon} alt="" />
                </div>
                <div className={styles.cardContentRight}>
                  <p>
                    {thisMonthData
                      ? thisMonth?.totalGuestPanditsThisMonth
                      : previousMonth?.totalGuestPanditsPreviousMonth}
                  </p>
                  <p>Guest Pandit</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.kycManagementTable}>
        <div className={styles.filterHeader}>
          <div className={styles.searchKYC}>
            <input
              type="text"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className={styles.filterExport}>
            <div className={styles.export}>
              <p onClick={handleExport}>Export as CSV, PDF, Excel doc</p>
            </div>
            {/* <div className={styles.filterDiv}>
              <p onClick={toggleFilterVisibility}>Filter</p>
            </div> */}
          </div>
        </div>
        <Spin spinning={loading} size="small">
          <div className={styles.tableMainContainer}>
            <table>
              <thead>
                <tr>
                  <th>S.no.</th>
                  <th>User Name</th>
                  <th>Pooja Type</th>
                  <th>Service Temple</th>
                  <th>Email ID</th>
                  <th>City</th>
                  <th>Service</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {panditsData.length > 0 ? (
                  panditsData.map((pandit, index) => (
                    <tr key={pandit?._id}>
                      <td>{index + 1}</td>
                      <td>{pandit?.userName} </td>
                      <td>{pandit?.poojaCategories}</td>
                      <td>{pandit?.templeNames}</td>
                      <td>{pandit?.email}</td>
                      <td>{pandit?.placesLived?.currentCity}</td>

                      <td
                        className={styles.kycViewDetails}
                        onClick={() =>
                          navigate(
                            `/panditprofilemanagement/viewpanditprofiledetails/${pandit?._id}`,
                            { state: { id: pandit?._id } }
                          )
                        }
                      >
                        View
                      </td>

                      <td className={styles.panditProfileStatus}>
                        <p>
                          Active <span><img src={downArrow} alt="" /></span>
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {panditsData.length > 0 ? (
            <div className={styles.footer}>
              <div
                className={styles.action}
                style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
              >
                <button
                  className={`${styles.actionButton} ${
                    page === 1 ? styles.disabled : styles.activeButton
                  }`}
                  onClick={handlePrevious}
                  disabled={page === 1}
                >
                  Previous
                </button>
                <button
                  className={`${styles.actionButton} ${
                    page === totalPages ? styles.disabled : styles.activeButton
                  }`}
                  onClick={handleNext}
                  disabled={page === totalPages}
                >
                  Next
                </button>
              </div>
              <div className={styles.page}>
                <span>{currentPage}</span> <span>of</span>{" "}
                <span>{totalPages}</span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </Spin>
      </div>
    </div>
  );
}

export default PanditProfileManagement;
