import React, { useEffect, useState } from "react";
import styles from "./panditKYCMgmt.module.css";
import cardIcon from "../../assets/images/cardIcon.png";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import Filter from "./KYCFilter/Filter";
import { Spin } from "antd";
import { getConfig } from "../../utils/getConfig";
import { toast } from "sonner";
import useDebounce from "../../utils/useDebounce";
import { handleExportDoc } from "../../utils/exportDoc";
import PageHeader from "../../components/PageHeader/PageHeader";

function PanditKYCManagement() {
  const [selectedOption, setSelectedOption] = useState("All");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [panditsData, setPanditsData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [cardData, setCardData] = useState("");
  let [thisMonthData, setThisMonthData] = useState(true);
  const [loading, setLoading] = useState(true);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [selectedOption, page, debouncedSearchQuery]);

  const fetchData = async () => {
    try {
      let url = `/admin/adminPanditKycRoutes?page=${page}&search=${debouncedSearchQuery}`;

      if (selectedOption !== "All") {
        url += `&status=${selectedOption}`;
      }

      const response = await userRequest.get(url);
      const responseCard = await userRequest.get(
        "/admin/adminPanditKycRoutes/getAdminKYCCardData"
      );
      setCardData(responseCard.data);
      setPanditsData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Request":
        return {
          color: "#FF9914",
          backgroundColor: "#FF991433",
        };
      case "Verified":
        return {
          color: "#1DBA13",
          backgroundColor: "#1DBA1333",
        };
      case "Send correction":
        return {
          color: "#FF0000",
          backgroundColor: "#FF000033",
        };
      case "Re submitted":
        return {
          color: "#5B5DFD",
          backgroundColor: "#5B5DFD33",
        };
      default:
        return {
          color: "black",
          borderColor: "black",
        };
    }
  };

  const {
    totalPandits,
    totalPanditsWithKYC,
    totalPanditsWithKYCPending,
    thisMonth,
    previousMonth,
  } = cardData;
  const [isFilterVisible, setFilterVisible] = useState(false);

  const toggleFilterVisibility = () => {
    setFilterVisible(!isFilterVisible);
  };

  const handleExport = () => {
    handleExportDoc(
      "/admin/adminPanditKycRoutes/exportExcelSheet",
      "PanditKYCManagement.xlsx"
    );
  };

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Pandit KYC Management", path: "/panditkycmanagement" },
        ]}
        buttonLabel={"View KYC Price"}
        onButtonClick={() => navigate("/panditkycmanagement/viewkycprice")}
      />
      <div className={styles.cardMain}>
        <div className={styles.cardLeftMain}>
          <div className={styles.singleCardLeft}>
            <div className={styles.cardIcon}>
              <img src={cardIcon} alt="" />
            </div>
            <div className={styles.cardContent}>
              <p>{totalPandits}</p>
              <p>Total Registered</p>
              <p>Pandit</p>
            </div>
          </div>
          <div className={styles.singleCardLeft}>
            <div className={styles.cardIcon}>
              <img src={cardIcon} alt="" />
            </div>
            <div className={styles.cardContent}>
              <p>{totalPanditsWithKYC}</p>
              <p>KYC Completed</p>
              <p>Pandit</p>
            </div>
          </div>
          <div className={styles.singleCardLeft}>
            <div className={styles.cardIcon}>
              <img src={cardIcon} alt="" />
            </div>
            <div className={styles.cardContent}>
              <p>{totalPanditsWithKYCPending}</p>
              <p>KYC Pending</p>
              <p>Pandit</p>
            </div>
          </div>
        </div>
        <div className={styles.cardRightMain}>
          <div className={styles.cardRightBox}>
            <div className={styles.cardRightHeader}>
              <p
                onClick={() => setThisMonthData(true)}
                style={{ color: thisMonthData ? "#ff9914" : "" }}
              >
                This Month
              </p>
              <p
                onClick={() => setThisMonthData(false)}
                style={{ color: !thisMonthData ? "#ff9914" : "" }}
              >
                Previous Month
              </p>
            </div>
            <div className={styles.cardRightContainer}>
              <div className={styles.singleCardRight}>
                <div className={styles.cardIconRight}>
                  <img src={cardIcon} alt="" />
                </div>
                <div className={styles.cardContentRight}>
                  <p>
                    {thisMonthData
                      ? thisMonth?.totalRegisteredPanditsThisMonth
                      : previousMonth?.totalRegisteredPanditsPreviousMonth}
                  </p>
                  <p>Registered Pandit</p>
                </div>
              </div>
              <div className={styles.singleCardRight}>
                <div className={styles.cardIconRight}>
                  <img src={cardIcon} alt="" />
                </div>
                <div className={styles.cardContentRight}>
                  <p>
                    {thisMonthData
                      ? thisMonth?.totalVerifiedPanditsThisMonth
                      : previousMonth?.totalVerifiedPanditsPreviousMonth}
                  </p>
                  <p>Verified Pandit</p>
                </div>
              </div>
              <div className={styles.singleCardRight}>
                <div className={styles.cardIconRight}>
                  <img src={cardIcon} alt="" />
                </div>
                <div className={styles.cardContentRight}>
                  <p>
                    {thisMonthData
                      ? thisMonth?.totalGuestPanditsThisMonth
                      : previousMonth?.totalGuestPanditsPreviousMonth}
                  </p>
                  <p>Guest Pandit</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.kycManagementTable}>
        <div className={styles.filterHeader}>
          <div
            className={styles.radioFilter}
            style={{ visibility: isFilterVisible ? "visible" : "hidden" }}
          >
            <div className={styles.radioOne}>
              <input
                type="radio"
                id="All"
                name="radioFilter"
                value="All"
                checked={selectedOption === "All"}
                onChange={handleOptionChange}
                className={styles.radioStyle}
              />
              <label
                htmlFor="All"
                className={selectedOption === "All" ? styles.activeRadio : ""}
              >
                All
              </label>
            </div>
            <div className={styles.radioTwo}>
              <input
                type="radio"
                id="Verified"
                name="radioFilter"
                value="Verified"
                checked={selectedOption === "Verified"}
                onChange={handleOptionChange}
                className={styles.radioStyle}
              />
              <label
                htmlFor="Verified"
                className={
                  selectedOption === "Verified" ? styles.activeRadio : ""
                }
              >
                Verified
              </label>
            </div>
            <div className={styles.radioThree}>
              <input
                type="radio"
                id="Send correction"
                name="radioFilter"
                value="Send correction"
                checked={selectedOption === "Send correction"}
                onChange={handleOptionChange}
                className={styles.radioStyle}
              />
              <label
                htmlFor="Send correction"
                className={
                  selectedOption === "Send correction" ? styles.activeRadio : ""
                }
              >
                Send For Correction
              </label>
            </div>
            <div className={styles.radioFour}>
              <input
                type="radio"
                id="Re submitted"
                name="radioFilter"
                value="Re submitted"
                checked={selectedOption === "Re submitted"}
                onChange={handleOptionChange}
                className={styles.radioStyle}
              />
              <label
                htmlFor="Re submitted"
                className={
                  selectedOption === "Re submitted" ? styles.activeRadio : ""
                }
              >
                Re submitted
              </label>
            </div>
          </div>
          <div className={styles.filterExport}>
            <div className={styles.export}>
              <p onClick={handleExport}>Export as CSV, PDF, Excel doc</p>
            </div>
            <div className={styles.filterDiv}>
              <p onClick={toggleFilterVisibility}>Filter</p>
              {/* <Filter /> */}
            </div>
          </div>
        </div>
        <div className={styles.searchKYC}>
          <input type="text" onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        <Spin spinning={loading} size="small">
          <div className={styles.tableMainContainer}>
            <table>
              <thead>
                <tr>
                  <th>S.no.</th>
                  <th>User Name</th>
                  <th>User Type</th>
                  <th>Gender</th>
                  <th>Email ID</th>
                  <th>City</th>
                  <th>KYC Details</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {panditsData.length > 0 ? (
                  panditsData.map((pandit, index) => (
                    <tr key={pandit?._id}>
                      <td>{index + 1}</td>
                      <td>
                        {pandit?.PanditDetails?.firstName}{" "}
                        {pandit?.PanditDetails?.lastName}
                      </td>
                      <td>Pandit</td>
                      <td>{pandit?.PanditDetails?.gender}</td>
                      <td>{pandit?.PanditDetails?.email}</td>
                      <td>{pandit?.currentAddress?.city}</td>
                      <td
                        className={styles.kycViewDetails}
                        onClick={() =>
                          navigate(
                            `/panditkycmanagement/viewkycdetails/${pandit?._id}`
                          )
                        }
                      >
                        View
                      </td>
                      <td>
                        {" "}
                        <div
                          className={styles.statusText}
                          style={getStatusStyle(pandit?.status)}
                        >
                          {pandit?.status}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {panditsData.length > 0 ? (
            <div className={styles.footer}>
              <div
                className={styles.action}
                style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
              >
                <button
                  className={`${styles.actionButton} ${
                    page === 1 ? styles.disabled : styles.activeButton
                  }`}
                  onClick={handlePrevious}
                  disabled={page === 1}
                >
                  Previous
                </button>
                <button
                  className={`${styles.actionButton} ${
                    page === totalPages ? styles.disabled : styles.activeButton
                  }`}
                  onClick={handleNext}
                  disabled={page === totalPages}
                >
                  Next
                </button>
              </div>
              <div className={styles.page}>
                <span>{currentPage}</span> <span>of</span>{" "}
                <span>{totalPages}</span>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </Spin>
      </div>
    </div>
  );
}

export default PanditKYCManagement;
