import React, { useEffect, useState } from "react";
import styles from "../CreateCampaign/CreateCampaign.module.css";
import { useNavigate, useParams } from "react-router-dom";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { userRequest } from "../../../../requestMethod";
import Stepper from "awesome-react-stepper";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";

function ViewCampaign() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [termsAndConditions, setTermsAndConditions] = useState("");

  useEffect(() => {
    const fetchCampaignDetails = async () => {
      try {
        const response = await userRequest.get(
          `/admin/svarna/getIndividualCampaignDetails/${id}`
        );
        setCampaignDetails(response.data.data);
        setTermsAndConditions(response.data.data.termsAndConditions);
      } catch (error) {
        console.error("Error fetching campaign details:", error);
      }
    };

    fetchCampaignDetails();
  }, []);

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          { label: "Svarna Management", path: "/svarnamanagement" },
          {
            label: "View Campaign",
            path: "#",
          },
        ]}
        discardLabel="Back"
        saveLabel="Edit"
        onDiscardClick={() => navigate("/svarnamanagement")}
        onSaveClick={() => navigate(`/svarnamanagement/editcampaign/${id}`)}
      />

      {/* Content container */}
      <div className={styles.contentContainer}>
        {/* Campaign Details section */}
        <div className={styles.section}>
          <p>Campaign Details</p>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Campaign Title</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="campaignTitle"
                  value={campaignDetails && campaignDetails?.campaignTitle}
                  disabled
                />
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Number of levels</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="campaignTitle"
                  value={campaignDetails && campaignDetails?.NoOfLevels}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Duration</p>
              </div>
              <div className={styles.fieldInputTwo}>
                <input
                  type="text"
                  name="campaignTitle"
                  value={
                    formatDate(
                      campaignDetails && campaignDetails?.Duration?.startDate
                    ) || ""
                  }
                  disabled
                />
                <input
                  type="text"
                  name="campaignTitle"
                  value={
                    formatDate(
                      campaignDetails && campaignDetails?.Duration?.endDate
                    ) || ""
                  }
                  disabled
                />
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Role</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="campaignTitle"
                  value={campaignDetails && campaignDetails?.roleId?.roleName}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Country</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="campaignTitle"
                  value={campaignDetails && campaignDetails?.countryId?.country}
                  disabled
                />
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>State</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="campaignTitle"
                  value={campaignDetails && campaignDetails?.stateId?.state}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Max Lakshmi Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="maxLakshmiCoins"
                  value={campaignDetails && campaignDetails?.maxLakshmiCoins}
                  disabled
                />
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Max Tej Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="maxTejCoins"
                  value={campaignDetails && campaignDetails?.maxTejCoins}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Rule </p>
              </div>
              <div className={`${styles.fieldInput} ${styles.fieldInputRule}`}>
                {campaignDetails &&
                  campaignDetails.rule.map((rule, index) => (
                    <div key={index} className={styles.rule}>
                      <div className={styles.rule}>
                        <textarea value={rule} disabled />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Terms & Conditions</p>
              </div>
              <div className={styles.fieldInput}>
                {termsAndConditions !== "" && (
                  <RichTextEditor value={termsAndConditions} disabled />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.horizontalRow}></div>
        <div className={styles.levelDetailsMain}>
          <p className={styles.levelDetailsHeading}>Level Details</p>
        </div>
        {campaignDetails && campaignDetails.levelDetails && (
        <Stepper
          strokeColor="#f68b21"
          fillStroke="#f68b21"
          activeColor="#f68b21"
          showProgressBar="true"
          activeProgressBorder="2px solid #f68b21"
          submitBtn={false}
          continueBtn={<button className={styles.stepperBtn}>Next</button>}
          backBtn={<button className={styles.stepperBtn}>Back</button>}
        >
          {campaignDetails &&
            campaignDetails?.levelDetails?.map((level, levelIndex) => (
              <div key={levelIndex} className={styles.stepperSubDiv}>
                <div key={levelIndex} className={styles.section}>
                  <div className={styles.section}>
                    <div className={styles.fieldContainer}>
                      <div className={styles.feildLeft}>
                        <div className={styles.fieldLabel}>
                          <p>Level {levelIndex + 1}</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.fieldContainer}>
                      <div className={styles.feildLeft}>
                        <div className={styles.fieldLabel}>
                          <p>Name of Level</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <input
                            type="text"
                            value={
                              campaignDetails.levelDetails[levelIndex]
                                ?.levelName
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className={styles.feildRight}>
                        <div className={styles.fieldLabel}>
                          <p>Number of Task</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <input
                            type="text"
                            value={
                              campaignDetails.levelDetails[levelIndex]
                                ?.totalTasks
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.fieldContainer}>
                      <div className={styles.feildLeft}>
                        <div className={styles.fieldLabel}>
                          <p>Max Coins</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <input
                            type="text"
                            value={
                              campaignDetails.levelDetails[levelIndex]?.maxCoins
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className={styles.feildRight}>
                        <div className={styles.fieldLabel}>
                          <p>Coin</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <input
                            type="text"
                            value={
                              campaignDetails.levelDetails[levelIndex]?.coins
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.section}>
                    <p>Task Details</p>
                    {level?.taskDetails.map((task, taskIndex) => (
                      <div
                        key={taskIndex}
                        className={`${styles.fieldContainer} ${styles.fieldContainerTwo}`}
                      >
                        <div className={styles.feildLeft}>
                          <div className={styles.fieldLabel}>
                            <p>Coin Value</p>
                          </div>
                          <div className={styles.fieldInput}>
                            <input
                              style={{ background: "white" }}
                              type="text"
                              value={
                                campaignDetails.levelDetails[levelIndex]
                                  ?.taskDetails[taskIndex]?.coinValue
                              }
                              disabled
                            />
                          </div>
                          <div className={styles.fieldLabel}>
                            {/* <p>Task {taskIndex + 1}</p> */}
                            <p>Task Type</p>
                          </div>
                          <div className={styles.fieldInput}>
                            <input
                              style={{ background: "white" }}
                              type="text"
                              value={
                                campaignDetails.levelDetails[levelIndex]
                                  ?.taskDetails[taskIndex]?.operationId?.title
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className={styles.feildRight}>
                          <div className={styles.fieldLabel}>
                            <p>Repeated Task</p>
                          </div>
                          <div className={styles.fieldInput}>
                            <input
                              style={{ background: "white" }}
                              type="text"
                              value={
                                campaignDetails.levelDetails[levelIndex]
                                  ?.taskDetails[taskIndex]?.taskCount
                              }
                              disabled
                            />
                          </div>
                          <div className={styles.fieldLabel}>
                            <p>Task Description</p>
                          </div>
                          <div className={styles.fieldInput}>
                            <textarea
                              style={{ background: "white" }}
                              value={
                                campaignDetails.levelDetails[levelIndex]
                                  ?.taskDetails[taskIndex]?.operationId
                                  ?.description
                              }
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </Stepper>
        )}
      </div>
    </div>
  );
}

export default ViewCampaign;

const formatDate = (isoDate) => {
  if (!isoDate) return ""; // Return empty string if isoDate is falsy
  const date = new Date(isoDate);
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();

  // Ensure month and day are two digits
  if (month.length === 1) {
    month = "0" + month;
  }
  if (day.length === 1) {
    day = "0" + day;
  }

  return `${year}-${month}-${day}`;
};
