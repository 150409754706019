import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";

const ViewIshtDhaiva = ({ fetchData, task }) => {
  const initialFormData = {
    daivaName: task?.daivaName,
    daivaPic: task?.daivaPic,
    daivaSlogan: task?.daivaSlogan,
  };

  const apiEndpoint = "/master/samhitatype";
  const formFields = [
    { name: 'daivaName', label: 'Daiva Name', type: 'text' },
    { name: 'daivaPic', label: 'Daiva Pic', type: 'image' },
    { name: 'daivaSlogan', label: 'Daiva Slogan', type: 'text' }
  ];

  return (
    <ViewItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="View Samhita"
      item={task}
    />
  );
};

export default ViewIshtDhaiva;
