import React from "react";
import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";

const ViewUtsavAttraction = ({ fetchData, item }) => {
  const initialFormData = {
    name: item?.name,
  };

  const apiEndpoint = "/master/getMasterUtsavAttraction";
 
  const formFields = [{ name: "name", label: "Name", type: "text" }];
  return (
    <ViewItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="View Utsav Attraction"
      item={item}
    />
  );
};

export default ViewUtsavAttraction;

