import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";

const AddCity = ({ fetchData }) => {
  const initialFormData = {
    cityId: "",
    city: "",
    state: "",
    latitude:"",
    longitude:""
  };

  const apiEndpoint = "/master/createMasterCities";
  const formFields = [
    { name: "cityId", label: "City Id", type: "text" },
    { name: "city", label: "City Name", type: "text" },
    { name: "state", label: "State Name", type: "text" },
    { name: "latitude", label: "latitude", type: "text" },
    { name: "longitude", label: "longitude", type: "text" },
  ];

  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="City"
      // uploadFieldName="icon"
      // fetchDataOptions={{
      //   languages: fetchLanguages,
      // }}
    />
  );
};

export default AddCity;
