import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";


const AddIshtDhaiva = ({ fetchData }) => {
  const initialFormData = {
    daivaName: '',
    daivaPic: '',
    daivaSlogan: ''
  };

  const formFields = [
    { name: 'daivaName', label: 'Daiva Name', type: 'text' },
    { name: 'daivaPic', label: 'Daiva Pic', type: 'file', uploadLabel: 'Upload Media' },
    { name: 'daivaSlogan', label: 'Daiva Slogan', type: 'text' }
  ];

  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={`/master/createDaiva`}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Isht Dhaiva"
      uploadFieldName="daivaPic" 
    />
  );
};

export default AddIshtDhaiva;
