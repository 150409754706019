import styles from "./viewSamhita.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import FileViewer from "./FileViewer";
import PageHeaderTwo from "../../../components/PageHeader/PageHeaderTwo";
function ViewSamhita() {
  const location = useLocation();
  const samhita = location.state && location.state.samhita;
  const navigate = useNavigate();
  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          { label: "Samhita Management", path: "/samhitamanagement" },
          {
            label: "View Samhita",
            path: "#",
          },
        ]}
        saveLabel={false}
        discardLabel="Back"
        onDiscardClick={() => navigate("/samhitamanagement")}
      />
      <div className={styles.contentContainer}>
        {samhita?.uploadPdf ? (
          <FileViewer
            fileType={samhita?.samhitaType}
            fileUrl={samhita?.uploadPdf}
          />
        ) : //  <FileViewer samhitatype={samhita?.samhitaType} fileUrl={samhita?.uploadPdf}/>
        null}
      </div>
    </div>
  );
}

export default ViewSamhita;
