import React, { useState, useEffect } from "react";
import Select from "react-select";
import dropdown from "../assets/images/dropdown.png";

function SelectComponent({ options, white, onSelect, initialSelectedOption }) {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (initialSelectedOption) {
      setSelectedOption({ value: initialSelectedOption, label: initialSelectedOption });
    }
  }, [initialSelectedOption]);

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelect(selectedOption);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "10px",
      height: "3rem",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontSize: "0.8rem",
      background: white ? "white" : "#FFF9F1",
      fontWeight: "500",
      paddingLeft: "14px",
      
      // "@media (max-width: 767px)": {
      //   width: "93svw",
      //   // marginLeft:"1svw"
      // },
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      marginBottom: "1rem",
      

      backgroundColor: "white",
      color: "black",
      zIndex:"1000",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "2rem",
      backgroundColor: "white",
      color: "black",
      cursor: "pointer",
      backgroundColor: state.isFocused ? "#ff9914" : "white",

      borderBottom: "1px solid white",
      textAlign: "left",
      fontSize: "1rem",
      fontWeight: "500",
      width:"100%",
      
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleSelectChange}
      options={options}
      styles={customStyles}
      isSearchable={false}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => (
          <img
            src={dropdown}
            alt="Custom Dropdown Indicator"
            style={{ width: "10px", height: "8px", marginRight: "1.5vw" }}
          />
        ),
      }}
    />
  );
}

export default SelectComponent;
