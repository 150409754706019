import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./addPandit.module.css";
import { toast } from "sonner";
import SelectComponent from "../../../components/SelectComponent";
import FileUpload from "../../../components/FileUpload/FileUpload";
import { userRequest } from "../../../requestMethod";

const AddPandit = ({
  isModalOpen,
  handleCancel,
  refreshData,
  setRefreshData,
}) => {
  const initialFormData = {
    firstName: "",
    lastName: "",
    gender: "",
    mobile: "",
    email: "",
    profilePic: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [resetKey, setResetKey] = useState(0);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear error message for the changed field
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    // Additional validations for specific fields
    if (name === "mobile") {
      if (
        value &&
        (!/^\+?\d{1,15}$/.test(value) || isNaN(value.replace(/^\+/, "")))
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobile:
            "Mobile number must be numeric, up to 15 digits, and must be start with + country code.",
        }));
      }
    } else if (name === "email" && value && !/\S+@\S+\.\S+/.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is invalid.",
      }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    const { firstName, lastName, gender, mobile, email } = formData;

    if (!firstName) {
      newErrors.firstName = "First name is required.";
    }
    if (!lastName) {
      newErrors.lastName = "Last name is required.";
    }
    if (!gender) {
      newErrors.gender = "Gender is required.";
    }
    if (!mobile) {
      newErrors.mobile = "Mobile number is required.";
    }
    if (!email) {
      newErrors.email = "Email is required.";
    }
    if (!formData.profilePic) {
      newErrors.profilePic = "Profile picture is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleOk = async () => {
    if (validateForm()) {
      try {
        await userRequest.post(`/userManagement/createPandit`, formData);
        toast.success("Pandit created successfully");
        resetForm();
        handleCancel();
        setRefreshData(!refreshData);
      } catch (error) {
        console.log("Error creating Pandit:", error);
        toast.error("Failed to create Pandit");
      }
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setErrors({}); // Reset errors
    setResetKey((prevKey) => prevKey + 1); // Change the reset key
  };

  const option = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const handleGenderSelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      gender: selectedOption.value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, gender: "" })); // Clear gender error
  };

  return (
    <Modal
      visible={isModalOpen}
      onCancel={() => {
        resetForm();
        handleCancel();
      }}
      onOk={handleOk}
      width={"29vw"}
      className={styles.modal}
    >
      <div className={styles.modalTitle}>
        <p>Add Pandit</p>
      </div>
      <div className={styles.contentMain}>
        {/* First Name Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>First Name *</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              key={`firstName-${resetKey}`} // Reset key for the input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Enter"
            />
            {errors.firstName && (
              <span className={styles.error}>{errors.firstName}</span>
            )}
          </div>
        </div>

        {/* Last Name Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Last Name *</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              key={`lastName-${resetKey}`} // Reset key for the input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Enter"
            />
            {errors.lastName && (
              <span className={styles.error}>{errors.lastName}</span>
            )}
          </div>
        </div>

        {/* Gender Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Gender *</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              key={`gender-${resetKey}`} // Reset key for the select
              options={option}
              onSelect={handleGenderSelect}
            />
            {errors.gender && (
              <span className={styles.error}>{errors.gender}</span>
            )}
          </div>
        </div>

        {/* Mobile Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Mobile *</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              key={`mobile-${resetKey}`} // Reset key for the input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="Enter"
            />
            {errors.mobile && (
              <span className={styles.error}>{errors.mobile}</span>
            )}
          </div>
        </div>

        {/* Email Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Email *</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              key={`email-${resetKey}`} // Reset key for the input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter"
            />
            {errors.email && (
              <span className={styles.error}>{errors.email}</span>
            )}
          </div>
        </div>

        {/* Profile Pic Field */}
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Profile Pic *</p>
          </div>
          <div className={styles.fieldInput}>
            <FileUpload
              key={`profilePic-${resetKey}`} // Reset key for the file upload
              label="Upload Media"
              onChange={(fieldName, file) => {
                setFormData({ ...formData, profilePic: file });
                setErrors((prevErrors) => ({ ...prevErrors, profilePic: "" })); // Clear profile pic error
              }}
              fieldName="profilePic"
            />
            {errors.profilePic && (
              <span className={styles.error}>{errors.profilePic}</span>
            )}
          </div>
        </div>

        <div className={styles.modalButton}>
          <button
            onClick={() => {
              resetForm();
              handleCancel();
            }}
          >
            Cancel
          </button>
          <button onClick={handleOk}>Add</button>
        </div>
      </div>
    </Modal>
  );
};

export default AddPandit;
