import React, { useEffect, useState } from "react";
import styles from "./tejPoints.module.css";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import editIcon from "../../../assets/images/edit.png";
import { getConfig } from "../../../utils/getConfig";
import useDebounce from "../../../utils/useDebounce";
import TejPointsFilter from "./TejPointsFilter";
function TejPoints() {
  const { BASE_URL } = getConfig();
  const navigate = useNavigate();
  const [tejPointsData, setTejPointsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/tejPoints/getTejPoints?search=${debouncedSearchQuery}&page=${page}`
      );
      setTejPointsData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleViewClick = (tejPoint) => { 
    navigate(`/svarnamanagement/viewtejpoints`, { state: { tejPoint } }); 
  };
  const handleEditClick = (tejPoint) => {
    navigate(`/svarnamanagement/edittejpoints`, { state: { tejPoint } }); 
  };


  const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const handleModalOk = (data) => {
    setFilterData(data);
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.tableMain}>
      <div className={styles.filterExport}>
        {/* <p>All</p> */}
        <div className={styles.search}>
          <input type="text" onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        {/* <div className={styles.export}>
          <p>
            <a
              href={`${BASE_URL}/campaign/exportCampaignDataToExcel?search=${searchQuery}&page=${page}`}
              target="_parent"
            >
              Export as CSV, PDF, Excel doc
            </a>
          </p>
        </div> */}
        <div className={styles.filterDiv}>
          <p onClick={handleFilterClick}>Filter</p>
        </div>
      </div>
      <div className={styles.campaignTableMain}>
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Country</th>
              <th>State</th>
              <th>Login</th>
              <th>Add Temple</th>
              <th>Refer</th>
              <th>Create Utsav</th>
              <th>Booking</th>
              <th>Complete KYC</th>
              <th>Action & Status</th>
            </tr>
          </thead>
          <tbody>
            {tejPointsData.length > 0 ? (
              tejPointsData.map((tejPoint, index) => (
                <tr key={tejPoint._id}>
                  <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                  <td>{tejPoint.country}</td>
                  <td>{tejPoint.state}</td>
                  <td>{tejPoint.loginPoint}</td>
                  <td>{tejPoint.templePoint}</td>
                  <td>{tejPoint.referPoint}</td>
                  <td>{tejPoint.utsavPoint}</td>
                  <td>{tejPoint.bookingPoint}</td>
                  <td>{tejPoint.completeKycPoint}</td>
                  <td className={styles.actionTd}>
                    <span
                      className={styles.viewDetails}
                      onClick={() => handleViewClick(tejPoint)}
                    >
                      View
                    </span>                 

                    <img
                      src={editIcon}
                      alt=""
                      onClick={() => handleEditClick(tejPoint)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11">No data found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {tejPointsData.length > 0 ? (
        <div className={styles.footer}>
          <div
            className={styles.action}
            style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
          >
            <button
              className={`${styles.actionButton} ${
                page === 1 ? styles.disabled : styles.activeButton
              }`}
              onClick={handlePrevious}
              disabled={page === 1}
            >
              Previous
            </button>
            <button
              className={`${styles.actionButton} ${
                page === totalPages ? styles.disabled : styles.activeButton
              }`}
              onClick={handleNext}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
          <div className={styles.page}>
            <span>{currentPage}</span> <span>of</span> <span>{totalPages}</span>
          </div>
        </div>
      ) : (
        <div></div>
      )}
          <TejPointsFilter isModalOpen={isModalOpen} handleOk={handleModalOk} handleCancel={handleModalCancel} />
    
    </div>
  );
}

export default TejPoints;
