import React from "react";
import EditItem from "../../../../../components/Modals/MasterModals/EditItem";

const EditLanguage = ({ fetchData, item }) => {
  const initialFormData = {
    language: item?.language,
  };
  const apiEndpoint = "/master/updateMasterLanguage";
  const formFields = [{ name: "language", label: "Language", type: "text" }];
  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Language"
      item={item}
    />
  );
};
export default EditLanguage;
