import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "./addJobCategory.module.css";
import { toast } from "sonner";
import { userRequest } from "../../../../../requestMethod";

const AddJobCategory = ({ fetchData, selectedOption ,workingSectorData}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  let initialFormData;
  if (selectedOption === "workingSector") {
    initialFormData = {
      workingSector: "",
    };
  }
  if (selectedOption === "jobCategory") {
    initialFormData = {
      workingSectorType: "",
      jobTitle: "",
      jobTitleCode: "",
    };
  }

  const [formData, setFormData] = useState(initialFormData);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOk = async () => {
    setIsModalOpen(false);

    try {
      if (selectedOption === "workingSector") {
        await userRequest.post(`/master/createMasterWorkingSector`, formData);
      }
      if (selectedOption === "jobCategory") {
        await userRequest.post(`/master/createMasterJobCategory`, formData);
      }

      toast.success(
        `${
          selectedOption === "workingSector" ? "work sector" : "job category"
        } added successfully`
      );
      await fetchData();
      resetForm();
    } catch (error) {
      console.log("error", error?.response?.data?.message);
      // Show error message
      toast.error(error?.response?.data?.message);
    }
  };

  const handleCancel = () => {
    resetForm();
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setIsModalOpen(false);
    setResetKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      <button className={styles.addNewBtn} onClick={showModal}>
        Add New
      </button>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>{`Add ${
            selectedOption === "workingSector"
              ? "Working sector"
              : "Job Category"
          }`}</p>
        </div>
        <div className={styles.contentMain}>

        {
            selectedOption === "jobCategory" && (
              <>
              <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>
              Working Sector
              </p>
            </div>
            <div className={styles.fieldInput}>
              {/* <input
                type="text"
                name="jobTitleCode"
                value={formData.jobTitleCode}
                onChange={handleChange}
                key={`name-${resetKey}`}
              /> */}
               <select
                value={formData.workingSectorType || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    workingSectorType: e.target.value,
                  })
                }
              >
                <option value="" disabled>Select</option>
                {workingSectorData?.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.workingSector}
                  </option>
                ))}
              </select>
            </div>
          </div>
              </>
            )
          }

          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>
                {selectedOption === "workingSector"
                  ? "Working sector"
                  : "Job Title"}
              </p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name={
                  selectedOption === "workingSector"
                    ? "workingSector"
                    : "jobTitle"
                }
                value={
                  selectedOption === "workingSector"
                    ? formData.workingSector
                    : formData.jobTitle
                }
                onChange={handleChange}
                key={`name-${resetKey}`}
                placeholder="Enter"
              />
            </div>
          </div>
          {
            selectedOption === "jobCategory" && (
              <>
              <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>
              Job Code
              </p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="jobTitleCode"
                value={formData.jobTitleCode}
                onChange={handleChange}
                key={`name-${resetKey}`}
                placeholder="Enter"
              />
            </div>
          </div>
              </>
            )
          }

          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Add</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddJobCategory;
