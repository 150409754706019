import React, { useEffect, useState } from "react";
import styles from "./CreateFaq.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import PageHeaderTwo from "../../../../../components/PageHeader/PageHeaderTwo";
import { userRequest } from "../../../../../requestMethod";
import SelectComponent from "../../../../../components/SelectComponent";


function CreateFaq() {
  const navigate = useNavigate();

  const initialFormData = {
    question: "",
    answer: "",
    category: "",
    tags: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [faqCategories, setFaqCategories] = useState([]);
  // const tags = ["Devotee", "Pandit", "Profile" , "Utsav"];
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  // const userData = localStorage.getItem('USER_DETAILS');
  // let updatedByAdmin = JSON.parse(userData)
  // console.log("userData@",updatedByAdmin ,JSON.parse(userData))

  const fetchFaqCategories = async () => {
    try {
      let response = await userRequest.get("/admin/static/getAllCategories");
      setFaqCategories(response?.data?.data);
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Error saving data");
    }
  };
  useEffect(() => {
    fetchFaqCategories();
  }, []);

  const categoriesOptions = faqCategories?.map((item) => ({
    value: item?._id,
    label: item?.name,
  }));

  const tagsOptions = tags.map((tag) => ({
    value: tag,
    label: tag,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveClick = async () => {
 
   
    // Validation
    if (!formData?.question || formData?.question.trim() === "") {
      toast.error("question is required and cannot be empty.");
      return;
    }
    if (!formData?.answer || formData?.answer.trim() === "") {
      toast.error("answer is required and cannot be empty.");
      return;
    }
    if (!formData?.category ) {
      toast.error("category is required ");
      return;
    }
    if (tags?.length===0 ) {
      toast.error("tags is required.");
      return;
    }

 
   
      try {
        // console.log("formData", formData);
        let datatoSave = {
          ...formData,
          tags:tags
        };
        console.log("datatoSave", datatoSave)
        await userRequest.post("/admin/static/createFAQs", datatoSave);
        setFormData(initialFormData);
        navigate("/staticcontents/faq");
        toast.success("Saved successfully!");
      } catch (error) {
        console.error("Error saving data:", error.message);
        toast.error("Error saving data");
      }
    
  };


  const handleDiscard = () => {
    navigate("/staticcontents/faq");
  };

  const breadcrumb = [
    { label: "Faq", path: "/staticcontents/faq" },
    { label: "Create Faq", path: "/staticcontents/faq/createFaq" },
  ];

  const getSelectedLabel = (value, options) => {
    const selectedOption = options.find((option) => option.value === value);
    return selectedOption ? selectedOption.label : "Select";
  };
  console.log("formData",formData)
  

  const handleAddTag = () => {
    if (inputValue.trim() !== '' && !tags.includes(inputValue)) {
      setTags([...tags, inputValue.trim()]);
      setInputValue('');  // Clear input after adding tag
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddTag();
    }
  };
console.log("tags" , tags)
  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={breadcrumb}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={handleDiscard}
        onSaveClick={handleSaveClick}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.createContainer}>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Question</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="question"
                    value={formData.question}
                    onChange={handleChange}
                    placeholder="Enter"
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Answer</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="answer"
                    value={formData.answer}
                    onChange={handleChange}
                    placeholder="Enter"
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Category</p>
                </div>
                <div className={styles.fieldInputForTagAndCategory}>
                  <SelectComponent
                    options={categoriesOptions}
                    onSelect={(selectedOption) =>
                      setFormData({
                        ...formData,
                        category: selectedOption.value,
                      })
                    }
                    initialSelectedOption={getSelectedLabel(
                      formData?.category,
                      categoriesOptions
                    )}
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Tags</p>
                </div>
                <div className={styles.fieldInputForTagAndCategory}>
                  {/* <SelectComponent
                    options={tagsOptions}
                    onSelect={(selectedOption) =>
                      setFormData({
                        ...formData,
                        tags: selectedOption.value,
                      })
                    }
                    initialSelectedOption={formData?.tags}
                  /> */}
              
                    {/* <Select
                      options={tagsOptions}
                      isMulti
                      name="tags"
                      // className="basic-multi-select"
                      styles={customStyles}
                      classNamePrefix="select"
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions.map(option => option.value);
                        setFormData({
                          ...formData,
                          tags: selectedValues, // Update the tags field with selected values
                        });
                      }}
                    /> */}
     
      <div className={styles.mainTagDiv}>
      {tags.map((tag, index) => (
          <div key={index} 
          className={styles.tagDiv}
    >
            {tag}
            <button onClick={() => handleRemoveTag(tag)} 
            className={styles.removeTagButton}
           >
              &times;
            </button>
          </div>
        ))}
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Type a tag and press Enter"
      />
      
      </div>
      {/* <button onClick={handleAddTag}>Add Tag</button> */}
                </div>
              </div>
            </div>

            <div className={styles.footer}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateFaq;
