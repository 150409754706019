import React, { useEffect, useState } from "react";
import styles from "./ViewFaq.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeaderTwo from "../../../../../components/PageHeader/PageHeaderTwo";


function ViewFaq() {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location?.state?.item;
  console.log("item" , item)

  let tagValue = item?.tags?.map((ele)=>ele).join(',')

  const initialFormData = {
    question: item?.question || "",
    answer: item?.answer || "",
    category:item?.category || "",
    tagValue:tagValue || "",
    history:item?.history || ""
 
  };

  const [formData, setFormData] = useState(initialFormData);



  const handleDiscard = () => {
    navigate("/staticcontents/faq");
  };

  const breadcrumb = [
    { label: "Faq", path: "/staticcontents/faq" },
    { label: "View Faq", path: "/staticcontents/faq/viewFaq" },
  ];


  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={breadcrumb}
        discardLabel="Discard"
        // saveLabel="Save"
        onDiscardClick={handleDiscard}
        // onSaveClick={handleSaveClick}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.createContainer}>
           
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                  <p>Question</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="question"
                    value={formData.question}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
               
                <div className={styles.fieldLabel}>
                  <p>Answer</p>
                </div>
                <div className={styles.fieldInput}>
                <input
                    type="text"
                    name="answer"
                    value={formData.answer}
                    disabled

                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Category</p>
                </div>
                <div className={styles.fieldInput}>
                <input
                    type="text"
                    name="category"
                    value={formData.category?.name}
                    disabled

                  />
                
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Tags</p>
                </div>
                <div className={styles.fieldInput}>
                <input
                    type="text"
                    name="tags"
                    value={formData.tagValue}
                    disabled

                  />
                </div>
              </div>
            </div>
            
          
            <div className={styles.footer}></div>
          </div>
        </div>
        <div style={{ marginTop: "1rem" }}>
            <label style={{color:"black"}}>History</label>
          </div>
          <div
            style={{
              backgroundColor: "#FFF9F1",
              marginTop: "1rem",
              width: "100%",
              overflowX: "auto",
            }}
          >
            <table>
              <thead>
                <tr>
                  <th>Version</th>
                  <th>Question</th>
                  <th>Answer</th>


                </tr>
              </thead>
              <tbody>
                {formData?.history?.map((ele)=>(
                  <>
                  
           <tr key={ele._id}>
           <td>
            {ele?.version}
</td>
    
           <td>
            {ele?.question}

           </td>
           
           <td>
            {ele?.answer}

           </td>
       

           </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
      </div>
    </div>
  );
}

export default ViewFaq;


