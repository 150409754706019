import React, { useEffect, useState } from "react";
import styles from "./childKYCMgmt.module.css";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { Spin } from "antd";
import { handleExportDoc } from "../../utils/exportDoc";
import useDebounce from "../../utils/useDebounce";
import PageHeader from "../../components/PageHeader/PageHeader";
import TableComponent from "../../components/TableComponent/TableComponent";

function ChildKYCManagement() {
  const [selectedOption, setSelectedOption] = useState("all");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [childKYCData, setChildKYCData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [loading, setLoading] = useState(true);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [selectedOption, page, debouncedSearchQuery]);

  let url = `/childKyc/getAllChildKycData?page=${page}&search=${debouncedSearchQuery}`;

  if (selectedOption !== "all") {
    url += `&filterType=${selectedOption}`;
  }

  const fetchData = async () => {
    try {
      const response = await userRequest.get(url);
      setChildKYCData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Requested":
        return {
          color: "#FF9914",
          backgroundColor: "#FF991433",
        };
      case "Verified":
        return {
          color: "#1DBA13",
          backgroundColor: "#1DBA1333",
        };

      default:
        return {
          color: "black",
          borderColor: "black",
        };
    }
  };

  const [isFilterVisible, setFilterVisible] = useState(false);

  const toggleFilterVisibility = () => {
    setFilterVisible(!isFilterVisible);
  };

  const handleExport = () => {
    handleExportDoc("/childKyc/exportChildKyc", "childKYCManageMent.xlsx");
  };

  const columns = [
    { title: "S.no.", key: "index", render: (item, index) => index + 1 },
    {
      title: "Name",
      key: "name",
      render: (item) =>
        `${item.childDetails.firstName} ${item.childDetails.lastName}`,
    },
    {
      title: "UserName",
      key: "userName",
      render: (item) => item.childDetails.userName,
    },
    {
      title: "Gender",
      key: "gender",
      render: (item) => item.childDetails.gender,
    },
    {
      title: "Supervised By",
      key: "supervisedBy",
      render: (item) =>
        `${item.parentDetails.firstName} ${item.parentDetails.lastName}`,
    },
    {
      title: "KYC Details",
      key: "kycDetails",
      render: (item) => (
        <div
          className={styles.kycViewDetails}
          onClick={() =>
            navigate(`/childkycmanagement/viewchildkycdetails`, {
              state: { child: item },
            })
          }
        >
          View
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (item) => (
        <div className={styles.statusText} style={getStatusStyle(item.status)}>
          {item.status}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Child KYC Management", path: "/childkycmanagement" },
        ]}
      />
      <div className={styles.childKycManagementTable}>
        <div className={styles.filterHeader}>
          <div
            className={styles.radioFilter}
            style={{ visibility: isFilterVisible ? "visible" : "hidden" }}
          >
            <div className={styles.radioOne}>
              <input
                type="radio"
                id="all"
                name="radioFilter"
                value="all"
                checked={selectedOption === "all"}
                onChange={handleOptionChange}
                className={styles.radioStyle}
              />
              <label
                htmlFor="all"
                className={selectedOption === "all" ? styles.activeRadio : ""}
              >
                All
              </label>
            </div>
            <div className={styles.radioTwo}>
              <input
                type="radio"
                id="Verified"
                name="radioFilter"
                value="Verified"
                checked={selectedOption === "Verified"}
                onChange={handleOptionChange}
                className={styles.radioStyle}
              />
              <label
                htmlFor="Verified"
                className={
                  selectedOption === "Verified" ? styles.activeRadio : ""
                }
              >
                Verified
              </label>
            </div>
          </div>
          <div className={styles.filterExport}>
            <div className={styles.export}>
              <p onClick={handleExport}>Export as CSV, PDF, Excel doc</p>
            </div>
            <div className={styles.filterDiv}>
              <p onClick={toggleFilterVisibility}>Filter</p>
            </div>
          </div>
        </div>
        <div className={styles.search}>
          <input type="text" onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        <div className={styles.contentContainer}>
          <TableComponent
            columns={columns}
            data={childKYCData}
            loading={loading}
            onDelete={() => {}}
            onStatusChange={() => {}}
            currentPage={currentPage}
            totalPages={totalPages}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        </div>
      </div>
    </div>
  );
}

export default ChildKYCManagement;
