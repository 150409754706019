// import React, { useState } from "react";
// import { Modal } from "antd";
// import styles from "../AddTask/addTask.module.css";
// import { toast } from "sonner";
// import { useNavigate } from "react-router-dom";
// import editIcon from "../../../../../assets/images/edit.png";
// import { userRequest } from "../../../../../requestMethod";

// const EditTask = ({ fetchData, task }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const initialFormData = {
//     title: task?.title,
//     description: task?.description,
//     taskType: task?.taskType,
//     method: task?.method,
//     apiEndpoint: task?.apiEndpoint,
//     screenFlow: task?.screenFlow,
//   };
//   const [formData, setFormData] = useState(initialFormData);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleOk = async () => {
//     setIsModalOpen(false);

//     try {
//       const response = await userRequest.patch(
//         `/master/updateMasterTaskType/${task?._id}`,
//         formData
//       );
//       toast.success("task updated successfully");
//       await fetchData();
//       const taskData = response.data.data;
//       setFormData({
//         title: taskData?.title,
//         description: taskData?.description,
//         taskType: taskData?.taskType,
//         method: taskData?.method,
//         apiEndpoint: taskData?.apiEndpoint,
//         screenFlow: taskData?.screenFlow,
//       });
//       // Navigate("/taskmanagement");
//     } catch (error) {
//       console.log("Error creating task:", error);
//       // Show error message
//       toast.error("Failed to create task");
//     }
//   };

//   const handleCancel = () => {
//     resetForm();
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "title" || name === "description") {
//       const regex = /^[A-Za-z\s]*$/;
//       if (!regex.test(value)) {
//         toast.error("Please enter only letters");
//         return;
//       }
//     }

//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const resetForm = () => {
//     setFormData(initialFormData);
//     setIsModalOpen(false);
//   };

//   return (
//     <>
//       <img src={editIcon} alt="" onClick={showModal} />
//       <Modal
//         visible={isModalOpen}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         className={styles.modal}
//       >
//         <div className={styles.modalTitle}>
//           <p>Edit Task</p>
//         </div>
//         <div className={styles.contentMain}>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Title</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="title"
//                 value={formData.title}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Description</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <textarea
//                 name="description"
//                 value={formData.description}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Task Type</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="title"
//                 value={formData.taskType}
//                 onChange={handleChange}
//                 readOnly
//               />
//             </div>
//           </div>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>API End Point</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="title"
//                 value={formData.apiEndpoint}
//                 onChange={handleChange}
//                 readOnly
//               />
//             </div>
//           </div>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Method</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="title"
//                 value={formData.method}
//                 onChange={handleChange}
//                 readOnly
//               />
//             </div>
//           </div>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Screen Flow</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="title"
//                 value={formData.screenFlow}
//                 onChange={handleChange}
//                 readOnly
//               />
//             </div>
//             <div className={styles.modalButton}>
//               <button onClick={handleCancel}>Cancel</button>

//               <button onClick={handleOk}>Save</button>
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default EditTask;

import React from "react";
import EditItem from "../../../../../components/Modals/MasterModals/EditItem";

const EditTask = ({ fetchData, item }) => {
  const initialFormData = {
    title: item?.title,
    description: item?.description,
    taskType: item?.taskType,
    apiEndpoint: item?.apiEndpoint,
    method: item?.method,
    screenFlow: item?.screenFlow,


  };

  const apiEndpoint = "/master/updateMasterTaskType";
  const formFields = [
    { name: "title", label: "Title", type: "text" },
    { name: "description", label: "Description", type: "textarea" },
    { name: "taskType", label: "Task Type", type: "text" },
    { name: "apiEndpoint", label: "Api Endpoint", type: "text" },
    { name: "method", label: "Method", type: "text" },
    { name: "screenFlow", label: "Screen Flow", type: "text" },
  ];

  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Task"
      item={item}
    />
  );
};

export default EditTask;