import React from "react";
import EditItem from "../../../../../components/Modals/MasterModals/EditItem";

const EditCountries = ({ item,fetchData }) => {

  const initialFormData = {
    countryId: item?.countryId,
    country: item?.country,
    continent: item?.continent,
  };

  const apiEndpoint = "/master/updateMasterCountry";
  const formFields = [
    { name: "countryId", label: "Country Id", type: "text" },
    { name: "country", label: "Country Name", type: "text" },
    { name: "continent", label: "Continent Name", type: "text" },
  ];

  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Country"
      item={item}
    />
  );
};

export default EditCountries;

