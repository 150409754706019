import React from "react";
import styles from "./referredPandit.module.css";
import profile from "../../../../assets/images/profile.png";
import verified from "../../../../assets/images/verified.png";

function ReferredPandit({ data }) {
  const referredByDetails = data && data[0]?.ReferredByDetails;
  const assistedByDetails = data && data[0]?.AssistedByDetails;
  const reviewByDetails = data && data[0]?.reviewByDetails;

  return (
    <div className={styles.main}>
      <div className={styles.singleDetailContainer}>
        <div className={styles.detailContainer}>
          <div className={styles.detailsHeading}>
            <ul>
              <li>Referred Pandit Details</li>
            </ul>
          </div>
          {referredByDetails &&
            referredByDetails.map((referred, index) => (
              <div className={styles.details}>
                <div className={styles.profileImage}>
                  <div className={styles.profileImageCircle}>
                    <img src={profile} alt="" />
                  </div>
                </div>
                <div className={styles.detailsContent}>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Full Name</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{`${referred.firstName} ${referred.lastName}`}</p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Join Date</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{formatDate(referred.createdAt)}</p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Lived In</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{referred?.placesLived?.currentCity}</p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Mobile No.</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{referred.mobile}</p>
                      <span>
                        <img src={verified} alt="" />
                      </span>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Email</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{referred.email}</p>
                      {referred?.emailVerified === true ? (
                        <span>
                          <img src={verified} alt="" />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div
        className={`${styles.singleDetailContainer} ${styles.secondContainer}`}
      >
        <div className={styles.detailContainer}>
          <div className={styles.detailsHeading}>
            <ul>
              <li>Assisted By</li>
            </ul>
          </div>
          {assistedByDetails &&
            assistedByDetails.map((assisted, index) => (
              <div className={styles.details} style={{ marginBottom: "3%" }}>
                <div className={styles.profileImage}>
                  <div className={styles.profileImageCircle}>
                    <img src={profile} alt="" />
                  </div>
                </div>
                <div className={styles.detailsContent}>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Full Name</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{`${assisted.firstName} ${assisted.lastName}`}</p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Join Date</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{formatDate(assisted.createdAt) || "No Data"}</p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Lived In</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{assisted?.placesLived?.currentCity || "No Data"}</p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Mobile No.</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{assisted.mobile || "No Data"}</p>
                      <span>
                        <img src={verified} alt="" />
                      </span>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Email</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{assisted.email || "No Data"}</p>
                      {assisted?.emailVerified === true ? (
                        <span>
                          <img src={verified} alt="" />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div
        className={`${styles.singleDetailContainer} ${styles.secondContainer}`}
      >
        <div className={styles.detailContainer}>
          <div className={styles.detailsHeading}>
            <ul>
              <li>Reviewed By</li>
            </ul>
          </div>
          {reviewByDetails &&
            reviewByDetails.length > 0 &&
            reviewByDetails.map((reviewBy, index) => (
              <div className={styles.details} style={{ marginBottom: "3%" }}>
                <div className={styles.detailsContent}>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Full Name</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{reviewBy.name}</p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Email</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{reviewBy.email || "No Data"}</p>
                    </div>
                  </div>
                  {/* <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Role</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{reviewBy.role || "No Data"}</p>
                </div>
              </div> */}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ReferredPandit;

function formatDate(dateString) {
  // Convert dateString to Date object
  const date = new Date(dateString);

  // Format the date using toLocaleDateString method
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return formattedDate;
}
