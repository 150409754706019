import React, { useEffect, useState, useCallback } from "react";
import styles from "../CreateRoles/CreateRoles.module.css";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";

function EditRoles() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState({});

  const validateFields = () => {
    const newErrors = {};
    if (!data?.userId?.name) {
      newErrors.name = "Name is required.";
    }
    if (!data?.userId?.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(data?.userId?.email)) {
      newErrors.email = "Invalid email address.";
    }
    // if (!data?.password) {
    //   newErrors.password = "Password is required.";
    // }
    // if (!data?.confirmPassword) {
    //   newErrors.confirmPassword = "Confirm Password is required.";
    // } else if (data?.password !== data?.confirmPassword) {
    //   newErrors.confirmPassword = "Passwords do not match.";
    // }
    if (!data?.userId?.roleId?._id) {
      newErrors.userType = "User Type is required.";
    }
    if (!data?.userId?.designation) {
      newErrors.designation = "Designation is required.";
    }
    const hasSelectedPermissions = data?.permissions?.some(
      (permission) => permission.isSelected
    );
    if (!hasSelectedPermissions) {
      newErrors.permissions = "At least one permission is required.";
    }

    return newErrors;
  };

  const fetchPermissionsAndRoles = async () => {
    try {
      const [permissionsRes, rolesRes] = await Promise.all([
        userRequest.get("/permissions/getAllPermissions"),
        userRequest.get("/adminRoles/getAllAdminRoles"),
      ]);

      setPermissions(permissionsRes?.data?.data);
      setRoles(rolesRes?.data?.data);
    } catch (error) {
      console.error("Error fetching permissions and roles:", error.message);
    }
  };

  const fetchUserData = useCallback(
    async (userId) => {
      try {
        const response = await userRequest.get(
          `/adminUserPermission/getParticularUserForAdmin/${userId}`
        );

        const fetchedPermissions = response?.data?.data.permissions;
        const updatedPermissions = permissions.map((permission) => ({
          ...permission,
          isSelected: fetchedPermissions.some(
            (perm) =>
              perm.permissionsId.permissionName === permission.permissionName
          ),
        }));

        setData({ ...response?.data?.data, permissions: updatedPermissions });
      } catch (error) {
        console.log("Error fetching user data:", error.message);
      }
    },
    [permissions]
  );

  // Fetch initial data
  useEffect(() => {
    fetchPermissionsAndRoles();
  }, []);

  // Fetch user-specific data when permissions are loaded
  useEffect(() => {
    const userId = location?.state?.item?._id;
    if (userId && permissions.length) {
      fetchUserData(userId);
    }
  }, [location.state?.item?._id, permissions, fetchUserData]);

  //input feild changes
  const handleInputChange = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      userId: { ...prevData.userId, [field]: value },
    }));

    // Clear the specific field error
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "", // Clear the error for the given field
    }));
  };

  // Handle permission selection change
  const handlePermissionChange = useCallback((permissionId) => {
    setData((prevData) => {
      const updatedPermissions = prevData.permissions.map((permission) =>
        permission._id === permissionId
          ? { ...permission, isSelected: !permission.isSelected }
          : permission
      );

      // Check if any permission is selected after the change
      const hasSelectedPermissions = updatedPermissions.some(
        (permission) => permission.isSelected
      );

      // If a permission is selected, remove the permissions error from the errors state
      if (hasSelectedPermissions) {
        setErrors((prevErrors) => {
          const { permissions, ...remainingErrors } = prevErrors;
          return remainingErrors;
        });
      }

      return { ...prevData, permissions: updatedPermissions };
    });
  }, []);

  // Handle data saving
  const saveData = async () => {
    try {
      const validationErrors = validateFields();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      const selectedPermissions = data.permissions
        .filter((permission) => permission.isSelected)
        .map((permission) => ({ permissionsId: permission._id }));

      const payload = {
        name: data.userId?.name,
        email: data.userId?.email,
        password: data.password,
        confirmPassword: data.password,
        roleId: data.userId?.roleId._id,
        designation: data.userId?.designation,
        activeStatus: data.activeStatus,
        permissions: selectedPermissions,
      };

      await userRequest.put(
        `/adminUserPermission/updateUserForAdmin/${data?._id}`,
        payload
      );

      fetchUserData(location.state?.item?._id);
      toast.success("Saved successfully!");
      navigate("/rolesandpermissions");
    } catch (error) {
      console.log("Error saving data:", error.message);
      toast.error("Failed to save data.");
    }
  };

  const roleOptions = roles.map((role) => (
    <option key={role._id} value={role._id}>
      {role.roleName}
    </option>
  ));

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          {
            label: "Roles and Permissions Management",
            path: "/rolesandpermissions",
          },
          { label: "Edit", path: "#" },
        ]}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={() => navigate("/rolesandpermissions")}
        onSaveClick={saveData}
      />

      <div className={styles.contentContainer}>
        <h4>Basic Details</h4>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Name *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data?.userId?.name || ""}
                onChange={(e) => handleInputChange("name", e.target.value)}
                placeholder="Enter"
              />
            </div>
            {errors.name && <span className={styles.error}>{errors.name}</span>}
          </div>

          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Email Id *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data?.userId?.email || ""}
                onChange={(e) => handleInputChange("email", e.target.value)}
                placeholder="Enter"
              />
            </div>
            {errors.email && (
              <span className={styles.error}>{errors.email}</span>
            )}
          </div>

          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data?.userId?.password || ""}
                onChange={(e) => handleInputChange("password", e.target.value)}
                placeholder="Enter"
              />
            </div>
            {/* {errors.password && (
              <span className={styles.error}>{errors.password}</span>
            )} */}
          </div>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>UserType *</p>
            </div>
            <div className={styles.fieldInput}>
              <select
                value={data?.userId?.roleId?._id || ""}
                onChange={(e) => {
                  setData((prevData) => ({
                    ...prevData,
                    userId: {
                      ...prevData.userId,
                      roleId: {
                        ...prevData.userId.roleId,
                        _id: e.target.value,
                      },
                    },
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    userType: "",
                  }));
                }}
              >
                {roleOptions}
              </select>
            </div>
            {errors.userType && (
              <span className={styles.error}>{errors.userType}</span>
            )}
          </div>

          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Designation *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data?.userId?.designation || ""}
                onChange={(e) =>
                  handleInputChange("designation", e.target.value)
                }
                placeholder="Enter"
              />
            </div>
            {errors.designation && (
              <span className={styles.error}>{errors.designation}</span>
            )}
          </div>

          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Confirm Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data?.userId?.password || ""}
                onChange={(e) =>
                  handleInputChange("confirmPassword", e.target.value)
                }
                placeholder="Enter"
              />
            </div>
            {/* {errors.confirmPassword && (
              <span className={styles.error}>{errors.confirmPassword}</span>
            )} */}
          </div>
        </div>

        <div className={styles.permissionDiv}>
          <h5>Permissions *</h5>
          <div className={styles.rolesTable}>
            {errors.permissions && (
              <span className={styles.error}>{errors.permissions}</span>
            )}
            <table>
              <tbody>
                {data?.permissions?.map((permission) => (
                  <tr className={styles.firstRow} key={permission._id}>
                    <td>
                      <div className={styles.permissionName}>
                        <input
                          type="checkbox"
                          checked={permission.isSelected}
                          onChange={() =>
                            handlePermissionChange(permission._id)
                          }
                        />
                        <p className={styles.permissionNamePara}>
                          {permission?.permissionName}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRoles;
