import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";

const AddRules = ({ fetchData }) => {
  const initialFormData = {
    name: "",
    rules: "",
  };

  const apiEndpoint = "/master/createAdminMasterRules";
  const formFields = [{ name: "name", label: "Name", type: "text" } , { name: "rules", label: "Rules", type: "text" }];
  


  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Rules"
    />
  );
};

export default AddRules;