import React, { useEffect, useState } from "react";
import styles from "./setKYC.module.css";
import SelectComponent from "../../../../components/SelectComponent";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useParams } from "react-router-dom";

function KYCPrice({ formData, setFormData, setEditKYC, errors, setErrors }) {
  const [selectedDiscountType, setSelectedDiscountType] = useState(null);
  const [selectedTaxType, setSelectedTaxType] = useState(null);
  const [kycPrice, setKycPrice] = useState(formData.kycPrice || "");
  const [discountType, setDiscountType] = useState(formData.DiscountType || "");
  const [discountAmount, setDiscountAmount] = useState(
    formData.discountAmount || ""
  );
  const [discountPercent, setDiscountPercent] = useState(
    formData.discountPercent || ""
  );
  const [taxType, setTaxType] = useState(formData.taxType || "");
  const [taxPercentage, setTaxPercentage] = useState(
    formData.taxPercentage || ""
  );

  const { id } = useParams();
  const [kycPriceData, setkycPriceData] = useState(null);

  useEffect(() => {
    if (id) {
      fetchData();
      setEditKYC(true);
    } else {
      // Trigger calculation on initial load for new data
      calculateFinalAmount();
    }
  }, [id]);

  useEffect(() => {
    calculateFinalAmount();
  }, [
    kycPrice,
    discountType,
    discountAmount,
    discountPercent,
    taxType,
    taxPercentage,
  ]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/admin/adminPanditKycRoutes/getSingleAdminKycPrice/${id}`
      );
      const data = response.data.data;
      setkycPriceData(data);
      setFormData({
        country: data.country || "",
        state: data.state || "",
        kycPrice: data.kycPrice || "",
        DiscountType: data.DiscountType || "",
        discountPercent: data.discountPercent || "",
        discountAmount: data.discountAmount || "",
        finalAmount: data.finalAmount || "",
        taxType: data.taxType || "",
        taxPercentage: data.taxPercentage || "",
        finalTaxAmount: data.finalTaxAmount || "",
        startDate: data.startDate
          ? new Date(data.startDate).toISOString().split("T")[0]
          : "", // Convert to YYYY-MM-DD format
        expiryDate: data.expiryDate
          ? new Date(data.expiryDate).toISOString().split("T")[0]
          : "", // Convert to YYYY-MM-DD format
      });
      setKycPrice(data.kycPrice || "");
      setDiscountType(data.DiscountType || "");
      setDiscountAmount(data.discountAmount || "");
      setDiscountPercent(data.discountPercent || "");
      setTaxType(data.taxType || "");
      setTaxPercentage(data.taxPercentage || "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const selectCountry = (val) => {
    setFormData({
      ...formData,
      country: val,
      state: "", // Reset state when country changes
    });
  };

  const selectState = (val) => {
    setFormData({
      ...formData,
      state: val,
    });
  };

  const discountTypeOptions = [
    { value: "Percentage", label: "Percentage" },
    { value: "Percentage", label: "Fixed" },
  ];

  const taxTypeOptions = [
    { value: "GST", label: "GST" },
    { value: "VAT", label: "VAT" },
  ];

  const handleKycPriceChange = (e) => {
    const newKycPrice = e.target.value;
    setKycPrice(newKycPrice);
    setFormData({
      ...formData,
      kycPrice: newKycPrice,
    });
  };

  const handleDiscountTypeChange = (selectedOption) => {
    setSelectedDiscountType(selectedOption);
    setDiscountType(selectedOption.value);
    setFormData({
      ...formData,
      DiscountType: selectedOption.value,
      discountAmount: "", // Reset discount amount when changing discount type
      discountPercent: "", // Reset discount percent when changing discount type
    });
  };

  const handleDiscountAmountChange = (e) => {
    const newDiscountAmount = e.target.value;
    setDiscountAmount(newDiscountAmount);
    setFormData({
      ...formData,
      discountAmount: newDiscountAmount,
    });
  };

  const handleDiscountPercentChange = (e) => {
    const newDiscountPercent = e.target.value;
    setDiscountPercent(newDiscountPercent);
    if (kycPrice && newDiscountPercent) {
      const calculatedDiscountAmount =
        (parseFloat(newDiscountPercent) / 100) * parseFloat(kycPrice);
      setDiscountAmount(calculatedDiscountAmount.toFixed(2));
      setFormData({
        ...formData,
        discountPercent: newDiscountPercent,
        discountAmount: calculatedDiscountAmount.toFixed(2),
      });
    } else {
      setDiscountAmount("");
      setFormData({
        ...formData,
        discountPercent: newDiscountPercent,
        discountAmount: "",
      });
    }
  };

  const handleTaxTypeChange = (selectedOption) => {
    setSelectedTaxType(selectedOption);
    setTaxType(selectedOption.value);
    setFormData({
      ...formData,
      taxType: selectedOption.value,
    });
  };

  const handleTaxPercentageChange = (e) => {
    const newTaxPercentage = e.target.value;
    setTaxPercentage(newTaxPercentage);
    setFormData({
      ...formData,
      taxPercentage: newTaxPercentage,
    });
  };

  const calculateFinalAmount = () => {
    let finalAmount = parseFloat(kycPrice) || 0;
    let finalTaxAmount = 0;

    if (discountType === "percentage" && discountPercent) {
      const discountValue = (parseFloat(discountPercent) / 100) * kycPrice;
      finalAmount -= discountValue;
    } else if (discountType === "fixed" && discountAmount) {
      finalAmount -= parseFloat(discountAmount);
    }

    if (taxType && taxPercentage) {
      finalTaxAmount = (parseFloat(taxPercentage) / 100) * kycPrice;
    }

    const totalAmountWithTax = finalAmount + finalTaxAmount;

    setFormData((prevFormData) => ({
      ...prevFormData,
      finalAmount: totalAmountWithTax.toFixed(2),
      finalTaxAmount: finalTaxAmount.toFixed(2),
    }));
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Country</p>
          </div>
          <div className={styles.fieldInput}>
            <CountryDropdown
              value={formData.country}
              onChange={(val) => selectCountry(val)}
            />
            {errors.country && <p className={styles.error}>{errors.country}</p>}
          </div>
        </div>
        <div className={styles.feildRight}>
          <div className={styles.fieldLabel}>
            <p>State</p>
          </div>
          <div className={styles.fieldInput}>
            <RegionDropdown
              country={formData.country}
              value={formData.state}
              onChange={(val) => selectState(val)}
            />
            {errors.state && <p className={styles.error}>{errors.state}</p>}
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>KYC Price</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              type="text"
              value={kycPrice}
              onChange={handleKycPriceChange}
            />
            {errors.kycPrice && (
              <p className={styles.error}>{errors.kycPrice}</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Discount Type</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={discountTypeOptions}
              onSelect={handleDiscountTypeChange}
            />
            {errors.DiscountType && (
              <p className={styles.error}>{errors.DiscountType}</p>
            )}
          </div>
        </div>
        {selectedDiscountType?.value === "percentage" && (
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Discount Percentage</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={discountPercent}
                onChange={handleDiscountPercentChange}
                placeholder="Percentage"
              />
              {errors.discountPercent && (
                <p className={styles.error}>{errors.discountPercent}</p>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Discount Amount</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              type="text"
              value={discountAmount}
              onChange={handleDiscountAmountChange}
              placeholder={
                selectedDiscountType?.value === "percentage"
                  ? "Auto-calculated"
                  : "Amount"
              }
              readOnly={selectedDiscountType?.value === "percentage"}
            />
            {errors.discountAmount && (
              <p className={styles.error}>{errors.discountAmount}</p>
            )}
          </div>
        </div>
        <div className={styles.feildRight}>
          <div className={styles.fieldLabel}>
            <p>Final Amount</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" value={formData.finalAmount} readOnly />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Tax Type</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={taxTypeOptions}
              onSelect={handleTaxTypeChange}
            />
            {errors.taxType && <p className={styles.error}>{errors.taxType}</p>}
          </div>
        </div>
        <div className={styles.feildRight}>
          <div className={styles.fieldLabel}>
            <p>Tax Percentage</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              type="text"
              value={taxPercentage}
              onChange={handleTaxPercentageChange}
            />
            {errors.taxPercentage && (
              <p className={styles.error}>{errors.taxPercentage}</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Final Tax Amount</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" value={formData.finalTaxAmount} readOnly />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Duration</p>
          </div>
          <div className={styles.fieldDate}>
            <div>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                value={formData.startDate}
                onChange={(e) =>
                  setFormData({ ...formData, startDate: e.target.value })
                }
              />
              {errors.startDate && (
                <p className={styles.error}>{errors.startDate}</p>
              )}
            </div>
            <div>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                value={formData.expiryDate}
                onChange={(e) =>
                  setFormData({ ...formData, expiryDate: e.target.value })
                }
              />
              {errors.expiryDate && (
                <p className={styles.error}>{errors.expiryDate}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}></div>
    </div>
  );
}

export default KYCPrice;
