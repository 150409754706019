import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";

const AddRelationshipStatus = ({ fetchData }) => {
  const initialFormData = {
    name: "",
  };

  const apiEndpoint = "/master/createMasterRelationshipStatus";
  const formFields = [{ name: "name", label: "Name", type: "text" }];


  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="RelationshipStatus"
    />
  );
};

export default AddRelationshipStatus;

