import React, { useState } from "react";
import styles from "./userMgmt.module.css";
import User from "./User/User";
import AddPandit from "./AddPandit/AddPandit";
import PageHeader from "../../components/PageHeader/PageHeader";

function UserManagement() {

  const [refreshData,setRefreshData] = useState(false)
  const menuItems = [{ label: "User", state: "User" }];

  const [activeMenuItem, setActiveMenuItem] = useState("User");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePoliciesMenu = (menu) => {
    setActiveMenuItem(menu);
  };

  const showModal = () => {
    setIsModalOpen(true);
    setRefreshData(!refreshData)
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRefreshData(!refreshData)
  };

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[{ label: "User Management", path: "/usermanagement" }]}
        buttonLabel="Add Pandit"
        onButtonClick={handleButtonClick}
      />
      <div className={styles.contentContainer}>
        <div className={styles.menuContainer}>
          {menuItems.map((item) => (
            <div
              key={item.state}
              className={
                activeMenuItem === item.state
                  ? `${styles.singleMenu} ${styles.activeMenu}`
                  : styles.singleMenu
              }
              onClick={() => handlePoliciesMenu(item.state)}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>
        <div className={styles.activePage}>
          {activeMenuItem === "User" && <User refreshData={refreshData} setRefreshData={setRefreshData}/>}
          {/* {activeMenuItem === "Moderator" && <Pandit />} */}
        </div>
      </div>

      <AddPandit
        isModalOpen={isModalOpen}
        showModal={showModal}
        handleCancel={handleCancel}
        setIsModalOpen={setIsModalOpen}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
      />
    </div>
  );
}

export default UserManagement;
