import React, { useState } from "react";
import { Modal } from "antd";
import styles from "../AddKainKaryam/AddIsthaDhaiva.module.css";
import viewIcon from "../../../../../assets/images/viewIcons.png";

const ViewKainKaryam = ({ task }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={viewIcon} alt="" onClick={showModal} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
      
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>View KainKaryam</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" name="name" value={task.name} disabled />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Sub Text</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" name="subText" value={task.subText} disabled />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Upload Media</p>
            </div>
            <div className={styles.fieldInput}>
              <img
                src={task?.icon}
                alt=""
                style={{ width: "15%", height: "15%" }}
              />
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Ok</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewKainKaryam;
