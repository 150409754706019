import React from "react";
import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";


const ViewTask = ({ item,fetchData }) => {

    const initialFormData = {
      title: item?.title,
      description: item?.description,
      taskType: item?.taskType,
      apiEndpoint: item?.apiEndpoint,
      method: item?.method,
      screenFlow: item?.screenFlow,
  };

  const formFields = [
    { name: "title", label: "Title", type: "text" },
    { name: "description", label: "Description", type: "text" },
    { name: "taskType", label: "Task Type", type: "text" },
    { name: "apiEndpoint", label: "API Endpoint", type: "text" },
    { name: "method", label: "Method", type: "text" },
    { name: "screenFlow", label: "ScreenFlow", type: "text" },
  ];

  return (
    <ViewItem
      fetchData={fetchData}
      initialFormData={initialFormData}
      formFields={formFields}
      title="View Task"
      item={item}
    />
  );
};

export default ViewTask;


