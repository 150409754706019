import React, { useEffect, useState } from "react";
import styles from "./language.module.css";
import { userRequest } from "../../../../requestMethod.js";
import SearchFilterComponent from "../../../../components/SearchFilter/SearchFilterComponent.jsx";
import { handleExportDoc } from "../../../../utils/exportDoc.js";
import useDebounce from "../../../../utils/useDebounce.js";
import ViewLanguage from "./ViewLanguage/ViewLanguage.jsx";
import EditLanguage from "./EditLanguage/EditLanguage.jsx";
import AddLanguage from "./AddLanguage/AddLanguage.jsx";
import DeleteAction from "../../../../components/TableComponent/DeleteAction.jsx";
import StatusToggleAction from "../../../../components/TableComponent/StatusToggleAction.jsx";
import TableComponent from "../../../../components/TableComponent/TableComponent.jsx";

function Language() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/master/getMasterLanguage?search=${debouncedSearchQuery}&page=${page}`
      );
      setData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await userRequest.put(`/master/updateMasterLanguage/${id}` , {deleted:true});
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (id, activeStatus) => {
    try {
      const newActiveStatus = !activeStatus;
      await userRequest.put(`/master/updateMasterLanguage/${id}`, {
        active: newActiveStatus,
      });
      fetchData();
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };


  const handleExport = () => {
    handleExportDoc('/master/exportMasterLanguage', 'language.xlsx');
  };

  const columns = [
    {
      title: "S.No",
      key: "sno",
      render: (item, index) => (page - 1) * 10 + index + 1,
    },
    { title: "Language", key: "language" },
  ];

  const actions = [
    ({ item }) => <ViewLanguage item={item} />,
    ({ item }) => <EditLanguage fetchData={fetchData} item={item} />,
    // ({ item }) => <DeleteAction item={item} onDelete={handleDelete} />,
    ({ item }) => <StatusToggleAction item={item} onStatusChange={handleChange} />,
  ];


  return (
    <div className={styles.main}>
      <SearchFilterComponent
        setSearchQuery={setSearchQuery}
        onExportClick={handleExport}
        addComponent={() => <AddLanguage fetchData={fetchData} />}
      />
      <div className={styles.contentContainer}>
      <div className={styles.activePage}>
          <TableComponent
            columns={columns}
            data={data}
            loading={loading}
            actions={actions}
            // onDelete={handleDelete}
            onStatusChange={handleChange}
            currentPage={currentPage}
            totalPages={totalPages}
            onNext={() => setPage(page + 1)}
            onPrevious={() => setPage(page - 1)}
          />
        </div>
      </div>
    </div>
  );
}

export default Language;
