import React, { useState } from "react";
import styles from "../../../ChildKYCManagement/ViewChildKYC/ViewKYCDetails.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import ImageZoom from "../../../../components/Modals/ImageZoom/ImageZoom";

function ViewDocument() {
  const location = useLocation();
  const data = location.state || {};
  console.log("data", location.state);
  const id = "14567";

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Pandit KYC Management", path: "/panditkycmanagement" },
          {
            label: "Profile's KYC Details",
            path: `/panditkycmanagement/viewkycdetails/${id}`,
          },
          {
            label: "Document",
            path: `#`,
          },
        ]}
      />
      <div className={styles.detailMainContainer}>
        <div className={styles.rowContainer} style={{ marginTop: "3%" }}>
          <div className={styles.detailContainer}>
            <div className={styles.detailsHeading}>
              <ul>
                <li>Documents</li>
              </ul>
            </div>
            <div className={styles.details}>
              <div className={styles.detailsContent}>
                <div className={styles.singleDetail}>
                  <div className={styles.singleDetailLeft}>
                    <p>Document Type</p>
                  </div>
                  <div className={styles.singleDetailRight}>
                    <p>Passport</p>
                  </div>
                </div>
                <div className={styles.singleDetail}>
                  <div className={styles.singleDetailLeft}>
                    <p>Document Id/Number</p>
                  </div>
                  <div className={styles.singleDetailRight}>
                    <p>123456</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.detailContainer} style={{ marginTop: "6.5%" }}>
            <div className={styles.details}>
              <div className={styles.detailsContent}>
                <div className={styles.singleDetail}>
                  <div className={styles.singleDetailLeft}>
                    <p>Issuing Country</p>
                  </div>
                  <div className={styles.singleDetailRight}>
                    <p>USA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rowContainer} style={{ marginTop: "3%" }}>
          <div className={styles.documentImageContainer}>
            <div className={styles.detailsHeading}>
              <ul>
                <li>Document Images</li>
              </ul>
            </div>
            <div className={styles.singleDocumentContainer}>
              <div className={styles.documentSide}>
                <p>Front Side</p>
              </div>
              <div className={styles.documentImage}>
                {/* <img
                  src={
                    "https://tejdevbuck.s3.ap-south-1.amazonaws.com/imagecropper1725862359137.jpg"
                  }
                  alt="front"
                /> */}
                <ImageZoom
                  imageSrc={
                    "https://tejdevbuck.s3.ap-south-1.amazonaws.com/imagecropper1725862359137.jpg"
                  }
                />
              </div>
            </div>
            <div
              className={styles.singleDocumentContainer}
              style={{ marginTop: "3%" }}
            >
              <div className={styles.documentSide}>
                <p>Back Side</p>
              </div>
              <div className={styles.documentImage}>
                {/* <img
                  src={
                    "https://tejdevbuck.s3.ap-south-1.amazonaws.com/imagecropper1725862359137.jpg"
                  }
                  alt="back"
                /> */}
                <ImageZoom
                  imageSrc={
                    "https://tejdevbuck.s3.ap-south-1.amazonaws.com/imagecropper1725862359137.jpg"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewDocument;
