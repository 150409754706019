// import React, { useEffect, useState } from "react";
// import styles from "./FaqCategory.module.css";
// import { useNavigate } from "react-router-dom";
// import { fileRequest, userRequest } from "../../../../requestMethod";
// import editIcon from "../../../../assets/images/edit.png";
// import deleteIcon from "../../../../assets/images/delete.png";
// import { Popconfirm, Spin, Switch } from "antd";
// import { getConfig } from "../../../../utils/getConfig";
// import useDebounce from "../../../../utils/useDebounce";
// import viewIcon from "../../../../assets/images/viewIcons.png";

// function FaqCategory() {
//   const { BASE_URL } = getConfig();
//   const navigate = useNavigate();
//   const [data, setData] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const debouncedSearchQuery = useDebounce(searchQuery, 1000);
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [loading, setLoading] = useState(true);
//   const limit = 10;

//   const handleNext = () => {
//     if (page < totalPages) {
//       setPage(page + 1);
//     }
//   };

//   const handlePrevious = () => {
//     if (page > 1) {
//       setPage(page - 1);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [debouncedSearchQuery, page]);

//   const fetchData = async () => {
//     setLoading(true);
//     try {
//       const response = await fileRequest.get(
//         `/static/getAllCategories?search=${debouncedSearchQuery}&page=${page}&limit=${limit}`
//       );
//       setData(response?.data?.data);
//       setTotalPages(response?.data?.pagination?.totalPages);
//       setCurrentPage(response?.data?.pagination?.page);
//     } catch (error) {
//       console.log("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       await fileRequest.delete(`/static/deleteCategory/${id}`);
//       fetchData();
//     } catch (error) {
//       console.error("Error deleting user:", error);
//     }
//   };

//   const handleAddNew = () => {
//     navigate("/staticcontents/faq/createFaqCategory" );

//   };

//   return (
//     <Spin spinning={loading} size="small">
//       <div className={styles.tableMain}>
//         <div className={styles.filterExport}>
//           <div className={styles.search}>
//             <input
//               type="text"
//               onChange={(e) => setSearchQuery(e.target.value)}
//               value={searchQuery}
//             />
//           </div>

//           <div className={styles.AddNew} onClick={handleAddNew}>Add New</div>
//         </div>
//         <div className={styles.rolesTableMain}>
//           <table>
//             <thead>
//               <tr>
//                 <th>S.No.</th>
//                 <th>Category</th>
//                 <th>Description</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.length > 0 ? (
//                 data.map((item, index) => (
//                   <tr key={item._id}>
//                     <td>{index + 1}</td>
//                     <td>{item?.name}</td>
//                     <td>{item?.description?.slice(0,10)}...</td>

//                     <td className={styles.actionTd}>
//                     <img
//                         src={viewIcon}
//                         alt="view"
//                         onClick={() =>
//                           navigate(`/staticcontents/faq/viewFaqCategory/${item?._id}`, {
//                             state: { item },
//                           })
//                         }
//                       />
//                       <img
//                         src={editIcon}
//                         alt=""
//                         onClick={() =>
//                           navigate(
//                             `/staticcontents/faq/editFaqCategory/${item?._id}`,
//                             { state: { item } }
//                           )
//                         }
//                       />
//                       <Popconfirm
//                         title={`Are you sure to delete ${item?.name}?`}
//                         onConfirm={() => handleDelete(item?._id)}
//                         okText="Yes"
//                         cancelText="No"
//                         overlayClassName={styles.largePopconfirm}
//                       >
//                         <img src={deleteIcon} alt="" />
//                       </Popconfirm>

//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="8">No data found</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//         {data.length > 0 ? (
//           <div className={styles.footer}>
//             <div
//               className={styles.action}
//               style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
//             >
//               <button
//                 className={`${styles.actionButton} ${
//                   page === 1 ? styles.disabled : styles.activeButton
//                 }`}
//                 onClick={handlePrevious}
//                 disabled={page === 1}
//               >
//                 Previous
//               </button>
//               <button
//                 className={`${styles.actionButton} ${
//                   page === totalPages ? styles.disabled : styles.activeButton
//                 }`}
//                 onClick={handleNext}
//                 disabled={page === totalPages}
//               >
//                 Next
//               </button>
//             </div>
//             <div className={styles.page}>
//               <span>{currentPage}</span> <span>of</span>{" "}
//               <span>{totalPages}</span>
//             </div>
//           </div>
//         ) : (
//           <div></div>
//         )}
//       </div>
//     </Spin>
//   );
// }

// export default FaqCategory;

import React, { useEffect, useState } from "react";
import styles from "./FaqCategory.module.css";
import { userRequest } from "../../../../requestMethod";
import useDebounce from "../../../../utils/useDebounce";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import { useNavigate } from "react-router-dom";
import viewIcon from "../../../../assets/images/viewIcons.png";
import editIcon from "../../../../assets/images/edit.png";
import deleteIcon from "../../../../assets/images/delete.png";
import { Popconfirm, Spin, Switch } from "antd";
import PageHeader from "../../../../components/PageHeader/PageHeader";

function FaqCategory() {
  const limit = 10;
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page]);
  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/admin/static/getAllCategories?search=${debouncedSearchQuery}&page=${page}&limit=${limit}`
      );
      setData(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setCurrentPage(response?.data?.pagination?.currentPage);
      setPage(response?.data?.pagination?.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleView = (id, item) => {
    navigate(`/staticcontents/faq/viewFaqCategory/${id}`, {
      state: { item },
    });
  };
  const handleEdit = (id, item) => {
    navigate(`/staticcontents/faq/editFaqCategory/${id}`, {
      state: { item },
    });
  };

  const handleDelete = async (id) => {
    try {
      await userRequest.delete(`/admin/static/deleteCategory/${id}`, {
        deleted: true,
      });
      fetchData();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const columns = [
    {
      title: "S.No.",
      key: "serialNumber",
      render: (item, index) => (page - 1) * 10 + index + 1,
      // render: (item, index) => index + 1,
    },
    {
      title: "Category",
      key: "name",
      render: (item) => `${item?.name}`,
    },
    {
      title: "Description",
      key: "description",
      render: (item) => `${item?.description?.slice(0, 10)}...`,
    },
  ];

  const customWidths = {
    serialNumber: "8vw",
    name: "20vw",
    description: "20vw",
  };

  const handleAddNew = () => {
    navigate("/staticcontents/faq/createFaqCategory");
  };

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            label: "Faq Category",
            path: "/staticcontents/faq/createFaqCategory",
          },
        ]}
        buttonLabel="Add New"
        onButtonClick={() => handleAddNew()}
        backgroundWhite={true}
      />

      <div className={styles.faqSearch}>
        <input
          type="text"
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
      </div>
      <div className={styles.contentContainer}>
        {/* <SearchFilterComponent setSearchQuery={setSearchQuery}  />  */}

        <TableComponent
          columns={columns}
          data={data}
          loading={loading}
          onDelete={() => {}}
          currentPage={currentPage}
          totalPages={totalPages}
          onNext={handleNext}
          onPrevious={handlePrevious}
          customWidths={customWidths}
          actions={[
            (props) => (
              <img
                className={styles.viewDetails}
                src={viewIcon}
                alt="view"
                onClick={() => handleView(props?.item?._id, props.item)}
              />
            ),
            (props) => (
              <img
                className={styles.editDetails}
                src={editIcon}
                alt="edit"
                onClick={() => handleEdit(props?.item?._id, props.item)}
              />
            ),
            (props) => (
              <Popconfirm
                title={`Are you sure to delete ${props?.item?.name}?`}
                onConfirm={() => handleDelete(props?.item?._id)}
                okText="Yes"
                cancelText="No"
                overlayClassName={styles.largePopconfirm}
              >
                <img src={deleteIcon} alt="delete" />
              </Popconfirm>
            ),
          ]}
        />
      </div>
    </>
  );
}

export default FaqCategory;
