import React, { useState } from "react";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import styles from "./style.module.css";
import { fileRequest } from "../../requestMethod";

const FileUpload = ({ label, onChange, fieldName, name }) => {
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = async (e) => {
    setIsLoading(true);
    try {
      const file = e.target.files[0];
      const data = new FormData();
      data.append("file", file);

      // Check if the uploaded file is a PDF
      if (file.type === "application/pdf") {
        data.append("Content-Type", "application/pdf"); 
      }

      const response = await fileRequest.post("/util/uploadFile", data);
      onChange(fieldName, response.data.url);
      setFileName(file.name);
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.fileInput}>
      <span className={styles.fileName}>{(name ? name : fileName) || "Upload Image"}</span>
      <input
        type="file"
        id={`upload-${fieldName}-input`} 
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <label
        htmlFor={`upload-${fieldName}-input`}
        className={styles.uploadIcon}
      >
        {isLoading ? (
          <LoadingOutlined
            style={{ fontSize: "1.3rem", color: "#f68b21", cursor: "pointer" }}
          />
        ) : (
          <UploadOutlined
            style={{ fontSize: "1.3rem", color: "#f68b21", cursor: "pointer" }}
          />
        )}
      </label>
    </div>
  );
};

export default FileUpload;
