import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "../AddLocalGuide/AddLocalGuide.module.css";
import { toast } from "sonner";
import FileUpload from "../../../../../components/FileUpload/FileUpload";
import editIcon from "../../../../../assets/images/edit.png";
import { userRequest } from "../../../../../requestMethod";

const EditLocalGuide = ({ fetchData, task }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: task?.name,
    subText: task?.subText,
    icon: task?.icon,
  });
  const [errors, setErrors] = useState({
    name: "",
    subText: "",
    icon: "",
  });

  useEffect(() => {
    setFormData({
      name: task?.name,
      subText: task?.subText,
      icon: task?.icon,
    });
    setErrors({ name: "", subText: "", icon: "" }); 
  }, [task]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear the error for the changed field
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: "", subText: "", icon: "" };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    } else if (/\d/.test(formData.name)) {
      newErrors.name = "Name cannot contain numbers";
      valid = false;
    }
    if (!formData.icon) {
      newErrors.icon = "Icon is required";
      valid = false;
    }
    if (!formData.subText.trim()) {
      newErrors.subText = "SubText is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleOk = async () => {
    if (!validateForm()) {
      return;
    }
    setIsModalOpen(false);

    try {
      await userRequest.patch(`/master/editAdminMasterLocalTransport/${task?._id}`, formData);

      toast.success("Local Guide updated successfully");
      await fetchData();
      setFormData({
        name: task?.name,
        subText: task?.subText,
        icon: task?.icon,
      });
      setErrors({ name: "", subText: "", icon: "" }); 
    } catch (error) {
      console.log("Error updating Local Guide:", error);
      toast.error("Failed to update Local Guide");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={editIcon} alt="" onClick={showModal} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Edit Local Guide</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Name *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter"
              />
              {errors.name && <p className={styles.error}>{errors.name}</p>} 
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Sub Text *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="subText"
                value={formData.subText}
                onChange={handleChange}
                placeholder="Enter"
              />
              {errors.subText && <p className={styles.error}>{errors.subText}</p>} 
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Upload Media *</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Media"
                onChange={(fieldName, file) => {
                  setFormData({ ...formData, icon: file });
                  setErrors({ ...errors, icon: "" });
                }}
                fieldName="uploadMedia"
                name={formData?.icon}
              />
              {errors.icon && <p className={styles.error}>{errors.icon}</p>} 
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Update</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditLocalGuide;
