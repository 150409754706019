import React from "react";
import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";

const ViewRelationship = ({ item }) => {
  const formFields = [{ name: "name", label: "Name", type: "text" }];

  return (
    <ViewItem formFields={formFields} title="View Relationship" item={item} />
  );
};

export default ViewRelationship;
