import React, { useEffect, useState } from "react";
import styles from "./abhiruchi.module.css";
import { Spin } from "antd";
import { userRequest } from "../../../../requestMethod.js";
import ViewAbhiruchi from "./ViewAbhiruchi/ViewAbhiruchi.jsx";
import EditAbhiruchi from "./EditAbhiruchi/EditAbhiruchi.jsx";
import AddAbhiruchi from "./AddAbhiruchi/AddAbhiruchi.jsx";
import SearchFilterComponent from "../../../../components/SearchFilter/SearchFilterComponent.jsx";
import { handleExportDoc } from "../../../../utils/exportDoc.js";
import useDebounce from "../../../../utils/useDebounce.js";
import DeleteAction from "../../../../components/TableComponent/DeleteAction.jsx";
import StatusToggleAction from "../../../../components/TableComponent/StatusToggleAction.jsx";
import TableComponent from "../../../../components/TableComponent/TableComponent.jsx";
import SelectComponent from "../../../../components/SelectComponent.jsx";

function Abhiruchi() {
  const [selectedOption, setSelectedOption] = useState("abhiruchiType");
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [abhiruchiType, setAbhiruchiType] = useState("");
  const [abhiruchiOptions, setAbhiruchiOptions] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, abhiruchiType, selectedOption]);

  useEffect(() => {
    setSearchQuery("");
    setAbhiruchiType("");
    setShowFilter(false);
    setCurrentPage(1);
  }, [selectedOption]);

  useEffect(() => {
    setPage(1);
  }, [abhiruchiType]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const url =
        selectedOption === "abhiruchiType"
          ? `/master/getMasterAbhiruchiType?search=${debouncedSearchQuery}&page=${page}`
          : `/master/getMasterAbhiruchi?type=${abhiruchiType}&search=${debouncedSearchQuery}&page=${page}`;

      const response = await userRequest.get(url);
      setData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      if (selectedOption === "abhiruchiType") {
        await userRequest.put(`/master/updateMasterAbhiruchiType/${id}`, {
          deleted: true,
        });
      }
      if (selectedOption === "abhiruchi") {
        await userRequest.put(`/master/updateMasterAbhiruchi/${id}`, {
          deleted: true,
        });
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleChange = async (id, activeStatus) => {
    try {
      const newActiveStatus = !activeStatus;
      if (selectedOption === "abhiruchiType") {
        await userRequest.put(`/master/updateMasterAbhiruchiType/${id}`, {
          active: newActiveStatus,
        });
      }
      if (selectedOption === "abhiruchi") {
        await userRequest.put(`/master/updateMasterAbhiruchi/${id}`, {
          active: newActiveStatus,
        });
      }
      fetchData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleExport = () => {
    if (selectedOption === "abhiruchiType") {
      handleExportDoc(
        "/master/exportMasterAbhiruchiType",
        "abhiruchiType.xlsx"
      );
    }
    if (selectedOption === "abhiruchi") {
      handleExportDoc("/master/exportMasterAbhiruchi", "abhiruchi.xlsx");
    }
  };

  const fetchAbhiruchiType = async () => {
    try {
      const response = await userRequest.get(`/master/getMasterAbhiruchiType`);
      const options = [
        { value: "", label: "All" },
        ...response?.data?.data.map((item) => ({
          value: item?._id,
          label: item?.type,
        })),
      ];
      setAbhiruchiOptions(options);
    } catch (error) {
      console.error("Error fetching Abhiruchi type:", error);
    }
  };

  let abhiruchiSelected = selectedOption === "abhiruchi";

  useEffect(() => {
    fetchAbhiruchiType();
  }, [abhiruchiSelected]);

  useEffect(() => {
    const selectedOption = abhiruchiOptions.find(
      (option) => option.value === abhiruchiType
    );
    setSelectedLabel(selectedOption ? selectedOption.label : "");
  }, [abhiruchiOptions, abhiruchiType]);

  const columns =
    selectedOption === "abhiruchiType"
      ? [
          {
            title: "S.No",
            key: "sno",
            render: (item, index) => (page - 1) * 10 + index + 1,
          },
          {
            title: "Type",
            key: "type",
            render: (item) => item.type || "N/A",
          },
        ]
      : [
          {
            title: "S.No",
            key: "sno",
            render: (item, index) => (page - 1) * 10 + index + 1,
          },
          {
            title: "Type",
            key: "abhiruchiType",
            render: (item) => item.abhiruchiType?.type || "N/A",
          },
          { title: "Region", key: "region" },
          { title: "Name", key: "name" },
        ];

  const actions = [
    ({ item }) => {
      if (selectedOption === "abhiruchi") {
        return <ViewAbhiruchi item={item} />;
      }
      return null;
    },
    ({ item }) => (
      <EditAbhiruchi
        fetchData={fetchData}
        item={item}
        selectedOption={selectedOption}
      />
    ),
    // ({ item }) => <DeleteAction item={item} onDelete={handleDelete} />,
    ({ item }) => (
      <StatusToggleAction item={item} onStatusChange={handleChange} />
    ),
  ];

  const customWidths = {
    sno: "10dvw",
    type: selectedOption === "abhiruchiType" ? "50dvw" : "30dvw",
    region: "20dvw",
    name: "20dvw",
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className={styles.main}>
      <div className={styles.radioItems}>
        <div className={styles.radioDiv}>
          <input
            type="radio"
            id="abhiruchiType"
            name="abhiruchiType"
            value="abhiruchiType"
            checked={selectedOption === "abhiruchiType"}
            onChange={handleOptionChange}
            className={
              selectedOption === "abhiruchiType" ? styles.radioStyle : ""
            }
          />
          <label
            htmlFor="abhiruchiType"
            className={
              selectedOption === "abhiruchiType" ? styles.activeRadio : ""
            }
          >
            Abhiruchi Type
          </label>
        </div>
        <div className={styles.radioDiv}>
          <input
            type="radio"
            id="abhiruchi"
            name="abhiruchi"
            value="abhiruchi"
            checked={selectedOption === "abhiruchi"}
            onChange={handleOptionChange}
            className={selectedOption === "abhiruchi" ? styles.radioStyle : ""}
          />
          <label
            htmlFor="abhiruchi"
            className={selectedOption === "abhiruchi" ? styles.activeRadio : ""}
          >
            Abhiruchi
          </label>
        </div>
      </div>

      {selectedOption === "abhiruchi" && showFilter && (
        <div className={styles.abhiruchiType}>
          <div className={styles.abhiruchiHeading}>
            <p>Choose Abhiruchi Type</p>
          </div>

          <div className={styles.abhiruchiSelect}>
            <SelectComponent
              options={abhiruchiOptions}
              onSelect={(selectedOption) =>
                setAbhiruchiType(selectedOption.value)
              }
              initialSelectedOption={selectedLabel}
            />
          </div>
        </div>
      )}

      <SearchFilterComponent
        setSearchQuery={setSearchQuery}
        onExportClick={handleExport}
        onFilterClick={handleFilter}
        addComponent={() => (
          <AddAbhiruchi fetchData={fetchData} selectedOption={selectedOption} />
        )}
        label={selectedLabel}
        filter={selectedOption === "abhiruchi" ? true : false}
      />
      <div className={styles.contentContainer}>
        <TableComponent
          columns={columns}
          data={data}
          loading={loading}
          actions={actions}
          // onDelete={handleDelete}
          onStatusChange={handleChange}
          currentPage={currentPage}
          totalPages={totalPages}
          onNext={() => setPage(page + 1)}
          onPrevious={() => setPage(page - 1)}
          customWidths={customWidths}
        />
      </div>
    </div>
  );
}

export default Abhiruchi;
