import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";
import { userRequest } from "../../../../../requestMethod";

const AddPanditKyc = ({ fetchData }) => {
  const initialFormData = {
    type: "",
    question: "",
  };

  const apiEndpoint = "/master/createMasterPanditKyc";
  const formFields = [
    { name: "type", label: "Pandit Type", type: "select", optionsKey: "panditType",  optionValueKey: "_id", optionLabelKey: "type"},
    { name: "question", label: "Question", type: "text" },
  ];

  const fetchPanditType = async () => {
    try {
      const response = await userRequest.get(`/master/getAllPanditType`);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Pandit Kyc"
      // uploadFieldName="icon"
      fetchDataOptions={{
        panditType: fetchPanditType,
      }}
    />
  );
};

export default AddPanditKyc;
