import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";

const AddZipcode = ({ fetchData }) => {
  const initialFormData = {
    zipcode: "",
    city: "",
    state: "",
  };

  const apiEndpoint = "/master/createMasterZipCode";
  const formFields = [
    { name: "zipcode", label: "Zip Code", type: "text" },
    { name: "city", label: "City Name", type: "text" },
    { name: "state", label: "State Name", type: "text" },
  ];

  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Zipcode"
      // uploadFieldName="icon"
      // fetchDataOptions={{
      //   languages: fetchLanguages,
      // }}
    />
  );
};

export default AddZipcode;
