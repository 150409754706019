import React from "react";
import EditItem from "../../../../../components/Modals/MasterModals/EditItem";
import { userRequest } from "../../../../../requestMethod";

const EditAbhiruchi = ({ item, fetchData, selectedOption }) => {
  const initialFormData =
    selectedOption === "abhiruchiType"
      ? {
          type: item?.type,
        }
      : {
          abhiruchiType: item?.abhiruchiType?._id,
          name: item?.name,
          region: item?.region,
          description: item?.description,
        };

  const apiEndpoint =
    selectedOption === "abhiruchiType"
      ? "/master/updateMasterAbhiruchiType"
      : "/master/updateMasterAbhiruchi";
      
  const formFields =
    selectedOption === "abhiruchiType"
      ? [{ name: "type", label: "Type", type: "text" }]
      : [
          {
            name: "abhiruchiType",
            label: "Abhiruchi Type",
            type: "select",
            optionsKey: "abhiruchiType",
            optionValueKey: "_id",
            optionLabelKey: "type",
          },
          { name: "name", label: "Name", type: "text" },
          { name: "region", label: "Region", type: "text" },
          { name: "description", label: "Description", type: "text" },
        ];

  const fetchAbhiruchiType = async () => {
    try {
      const response = await userRequest.get(`/master/getMasterAbhiruchiType`);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title={
        selectedOption === "abhiruchiType" ? "Abhiruchi Type" : "Abhiruchi"
      }
      item={item}
      fetchDataOptions={{
        abhiruchiType: fetchAbhiruchiType,
      }}
    />
  );
};

export default EditAbhiruchi;
