import React, { useEffect, useState } from "react";
import styles from "./user.module.css";
import { userRequest } from "../../../requestMethod";
import { Popconfirm, Spin, Switch } from "antd";
import Filters from "./Filters";
import SelectDeactivateReason from "../Reason/SelectDeactivateReason";
import useDebounce from "../../../utils/useDebounce";
import TableComponent from "../../../components/TableComponent/TableComponent";
import SearchFilterComponent from "../../../components/SearchFilter/SearchFilterComponent";
import { handleExportDoc } from "../../../utils/exportDoc";
import { useNavigate } from "react-router-dom";


function User({refreshData}) {
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedReason, setSelectedReason] = useState("");
  const [id, setId] = useState("");
  const [userType, setUserType] = useState("");
  const [reasonModalVisible, setReasonModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, filterData,refreshData]);
  const fetchData = async () => {
    try {
      const filterQuery = filterData
        ? `&userType=${filterData?.userType}&city=${filterData.city}`
        : "";
      let response;

      if (filterQuery) {
        response = await userRequest.get(
          `/userManagement/getUserManagementList?search=${debouncedSearchQuery}${filterQuery}&page=${page}`
        );
      } else {
        response = await userRequest.get(
          `/userManagement/getUserManagementList?userType=All&search=${debouncedSearchQuery}${filterQuery}&page=${page}`
        );
      }

      setUserData(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setCurrentPage(response?.data?.pagination?.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (id, activeStatus, userType) => {
    try {
      setId(id);
      setUserType(userType);
      const newActiveStatus = !activeStatus;
      if (!newActiveStatus) {
        // If deactivating, show reason modal
        setSelectedReason("");
        setReasonModalVisible(true);
      } else {
        await userRequest.patch(`/userManagement/updatePanditOrDevoteeStatus`, {
          userId: id,
          userType: userType,
          activeStatus: newActiveStatus,
          deactivationReason: "",
        });
        fetchData();
      }
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const handleModalOk = (data) => {
    setFilterData(data);
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleExport = () => {
    handleExportDoc(
      `/userManagement/exportUserManagementToExcel?userType=All`,
      "UserManagement.xlsx"
    );
  };

  const columns = [
    {
      title: "S.No.",
      key: "serialNumber",
      render: (item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "User Name",
      key: "userName",
      render: (item) =>
        `${item.firstName} ${item.lastName}`,
    },
    { 
      title: "User Type", 
      key: "roleName" ,
      render: (item) =>
        `${item.roleId.roleName}`,
  
    },
    { title: "Email Id", key: "email" },
    { title: "Nationality", key: "nationality" },
    {
      title: "City",
      key: "city",
      render: (item) => item.placesLived?.currentCity,
    },
    {
      title: "Profile Completed",
      key: "profileCompletionPercentage",
      render: (item) => 
        `${item.profileCompletionPercentage}%`,
    },

  
    {
      title: "Action",
      key: "details",
      render: (item) => (
        <div
          className={styles.viewDetails}
          onClick={() =>
            navigate(`/usermanagement/details/${item._id}`)
          }
        >
          Details
        </div>
      ),
    },

    {
      title: "Status",
      key: "activeStatus",
      render: (item) => (
        <Popconfirm
          title={`Are you sure to ${
            item.activeStatus ? "deactivate" : "activate"
          } ${item.firstName} ${item.lastName}?`}
          onConfirm={() => handleChange(item._id, item.activeStatus , item.roleId.roleName)}
          okText="Yes"
          cancelText="No"
        >
          <Switch size="small" checked={item.activeStatus} />
        </Popconfirm>
      ),
    },
  ];

  const customWidths = {
    serialNumber: "8vw",
    userName: "20vw",
    roleName: "20vw",
    category: "10vw",
    email: "20vw",
    nationality: "8vw",
    city: "20vw",
    profileCompletionPercentage: "20vw",
    activeStatus: "20vw",
  };

  return (
    <>
  
          <SearchFilterComponent
            setSearchQuery={setSearchQuery}
            onExportClick={handleExport}
            onFilterClick={handleFilterClick}
            filter
          />

             <TableComponent
            columns={columns}
            data={userData}
            loading={loading}
            onDelete={() => {}}
            onStatusChange={handleChange}
            currentPage={currentPage}
            totalPages={totalPages}
            onNext={handleNext}
            onPrevious={handlePrevious}
            customWidths={customWidths}
          />
     
        <Filters
          isModalOpen={isModalOpen}
          handleOk={handleModalOk}
          handleCancel={handleModalCancel}
        />

        <SelectDeactivateReason
          selectedReason={selectedReason}
          setSelectedReason={setSelectedReason}
          isModalOpen={reasonModalVisible}
          setIsModalOpen={setReasonModalVisible}
          fetchData={fetchData}
          id={id}
          userType={userType}
        />
    </>
  );
}

export default User;
