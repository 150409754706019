import React, { useEffect, useState } from "react";
import styles from "./ishtDhaiva.module.css";
import { useNavigate } from "react-router-dom";
import { Popconfirm, Spin } from "antd";
import { userRequest } from "../../../../requestMethod.js";
import deleteIcon from "../../../../assets/images/deleteIcon.png";
import AddIshtDhaiva from "./AddIshtDhaiva/AddIshtDhaiva.jsx";
import ViewIshtDhaiva from "./ViewIshtDhaiva/ViewIshtDhaiva.jsx";
import EditIshtDhaiva from "./EditIshtDhaiva/EditIshtDhaiva.jsx";
import SearchFilterComponent from "../../../../components/SearchFilter/SearchFilterComponent.jsx";
import useDebounce from "../../../../utils/useDebounce.js";
import { formatDate } from "../../../../utils/DateFormat.js";

function IshtDhaiva() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, debouncedSearchQuery]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await userRequest.get(
        `/master/getDaiva?search=${debouncedSearchQuery}&page=${page}`
      );
      setData(response.data.daiva);
      setTotalPages(response.data.pagination.totalPages);
      setPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await userRequest.delete(`/master/deleteDaiva/${id}`);
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    console.log("Export clicked");
  };

  return (
    <div className={styles.main}>
      <SearchFilterComponent
        setSearchQuery={setSearchQuery}
        onExportClick={handleExport}
        addComponent={() => <AddIshtDhaiva fetchData={fetchData} />}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <Spin spinning={loading} size="small">
            <div className={styles.tableContainerIshtDhaiva}>
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Istha Devata Name</th>
                    <th>Image</th>
                    <th>Daiva Slogan</th>
                    <th>Last Update</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((task, index) => (
                      <tr key={task?._id}>
                        <td>{(page - 1) * 10 + (index + 1)}</td>
                        <td>{task?.daivaName}</td>
                        <td className={styles.tdIcon}>
                          <img src={task?.daivaPic} alt="" />
                        </td>
                        <td>{task?.daivaSlogan}</td>
                        <td>
                          {" "}
                          {/* {new Date(task?.updatedAt).toLocaleDateString()} */}
                          {formatDate(task?.updatedAt)}
                        </td>
                        <td className={styles.actionTd}>
                          <ViewIshtDhaiva task={task} />
                          <EditIshtDhaiva fetchData={fetchData} task={task} />
                        {/*  <Popconfirm
                            title={`Are you sure to delete ${task?.daivaName}?`}
                            onConfirm={() => handleDelete(task?._id)}
                            okText="Yes"
                            cancelText="No"
                            overlayClassName={styles.largePopconfirm}
                          >
                            <img src={deleteIcon} alt="" />
                          </Popconfirm>*/}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {data.length > 0 && (
                <div className={styles.footer}>
                  <div
                    className={styles.action}
                    style={{
                      visibility: totalPages === 1 ? "hidden" : "visible",
                    }}
                  >
                    <button
                      className={`${styles.actionButton} ${
                        page === 1 ? styles.disabled : styles.activeButton
                      }`}
                      onClick={handlePrevious}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                    <button
                      className={`${styles.actionButton} ${
                        page === totalPages
                          ? styles.disabled
                          : styles.activeButton
                      }`}
                      onClick={handleNext}
                      disabled={page === totalPages}
                    >
                      Next
                    </button>
                  </div>
                  <div className={styles.page}>
                    <span>{page}</span> <span>of</span>{" "}
                    <span>{totalPages}</span>
                  </div>
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default IshtDhaiva;
