import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Spin } from "antd";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import styles from "../CreateTermsAndPolices/CreateTermsAndPolices.module.css";

function ViewTermsAndPolicies() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state || {};
  const [data, setData] = useState(item);



  const breadcrumb = [
    {
      label:
        item?.type === "termsAndConditions"
          ? "Terms & Conditions"
          : "Privacy Policy",
      path:
        item?.type === "termsAndConditions"
          ? "/staticcontents/termsandconditions"
          : "/staticcontents/privacypolicy",
    },

    {
      label: "View",
      path:
        item?.type === "termsAndConditions"
          ? "/staticcontents/termsandconditions/view"
          : "/staticcontents/privacypolicy/view",
    },
  ];

  const handleDiscard = () => {
    item?.type === "termsAndConditions"
      ? navigate("/staticcontents/termsandconditions")
      : navigate("/staticcontents/privacypolicy");
  };

  const stripHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const renderContent = (content) => {
    const plainContent = stripHtml(content);
    return (
      <div>
        <span
        >
         {plainContent.substring(0, 20)}...
        </span>
      
      </div>
    );
  };


  return (
    <Spin spinning={isLoading} size="small">
      <div className={styles.main}>
        <PageHeaderTwo
          breadcrumb={breadcrumb}
          discardLabel="Back"
          onDiscardClick={handleDiscard}
        />

        <div className={styles.contentContainerTask}>
         
           <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Section</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="section"
                    value={data?.section}
                
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Language</p>
                </div>
                <div className={styles.fieldInput}>
               
                     <input
                    type="text"
                    name="language"
                    value={data?.language}
                  />
                </div>
              </div>
            </div>

          {data?.content !== null && (
            <>
              <label>Content</label>
              <RichTextEditor value={data?.content} disabled />
            </>
          )}
          <div style={{ marginTop: "1rem" }}>
            <label>History</label>
          </div>
          <div
            style={{
              backgroundColor: "#FFF9F1",
              marginTop: "1rem",
              width: "100%",
              overflowX: "auto",
            }}
          >
            <table>
              <thead>
                <tr>
                  <th>Version</th>
                  <th>Language</th>
                  <th>Content</th>

                </tr>
              </thead>
              <tbody>
                {data?.history?.map((ele)=>(
                  <>
                  
           <tr key={ele._id}>
           <td>
            {ele?.version}

           </td>
           <td>
            {ele?.language}

           </td>
         
           <td>
            <td>{renderContent(ele?.content)}</td>

           </td>

           </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default ViewTermsAndPolicies;
