import React, { useState } from "react";
import styles from "../CreateBanner/createBanner.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { userRequest } from "../../../requestMethod";
import FileUpload from "../../../components/FileUpload/FileUpload";
import SelectComponent from "../../../components/SelectComponent";
import PageHeaderTwo from "../../../components/PageHeader/PageHeaderTwo";

function EditBanner() {
  const navigate = useNavigate();
  const location = useLocation();
  const banner = location.state && location.state.banner;

  const initialFormData = {
    id: banner?._id || "",
    title: banner?.title || "",
    imageUrl: banner?.imageUrl || "",
    startDate: banner?.startDate || "",
    endDate: banner?.endDate || "",
    content: banner?.content || "",
    targetAudience: banner?.targetAudience || "",
    linkUrl: banner?.linkUrl || "",
    geographicalScope: banner?.geographicalScope || "",
    language: banner?.language || "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  const geographicalScopes = [
    "Local",
    "Regional",
    "National",
    "International",
    "Global",
  ];
  const targetAudiences = ["Existing customers", "New customers"];
  const languages = ["Hindi", "English", "Sanskrit"];

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  }

  const languageOptions = languages.map((language) => ({
    value: language,
    label: language,
  }));

  const geographicalScopeOptions = geographicalScopes.map((geo) => ({
    value: geo,
    label: geo,
  }));

  const targetAudienceOptions = targetAudiences.map((aud) => ({
    value: aud,
    label: aud,
  }));

  const validateForm = () => {
    let formErrors = {};
    if (!formData.title) formErrors.title = "Title is required";
    if (!formData.imageUrl) formErrors.imageUrl = "Image URL is required";
    if (!formData.startDate) formErrors.startDate = "Start Date is required";
    if (!formData.endDate) formErrors.endDate = "End Date is required";
    if (!formData.content) formErrors.content = "Content is required";
    if (!formData.targetAudience)
      formErrors.targetAudience = "Target Audience is required";
    if (!formData.geographicalScope)
      formErrors.geographicalScope = "Geographical Scope is required";
    if (!formData.language) formErrors.language = "Language is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSaveClick = async () => {
    if (validateForm()) {
      try {
        await userRequest.put(`master/updateMasterBanner`, formData);
        navigate("/bannerManagement");
        toast.success("Updated successfully!");
      } catch (error) {
        console.error("Error saving data:", error.message);
        toast.error("Error saving data");
      }
    }
  };

  const handleDiscard = () => {
    navigate("/bannermanagement");
  };

  const breadcrumb = [
    { label: "Banner Management", path: "/bannermanagement" },
    { label: "Update Banner", path: "/bannermanagement/edit" },
  ];

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={breadcrumb}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={handleDiscard}
        onSaveClick={handleSaveClick}
      />
      <div className={styles.contentContainer}>
        <div className={styles.tableContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Banner Title</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => {
                    setFormData({ ...formData, title: e.target.value });
                    setErrors({ ...errors, title: "" });
                  }}
                  placeholder="Enter"
                />
                {errors.title && (
                  <span className={styles.error}>{errors.title}</span>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Image URL</p>
              </div>
              <div className={styles.fieldInput}>
                <FileUpload
                  label="Upload Image"
                  onChange={(fieldName, file) => {
                    setFormData({ ...formData, imageUrl: file });
                    setErrors({ ...errors, imageUrl: "" });
                  }}
                  fieldName="uploadImage"
                  name={formData.imageUrl}
                />
                {errors.imageUrl && (
                  <span className={styles.error}>{errors.imageUrl}</span>
                )}
              </div>
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Content</p>
              </div>
              <div className={styles.fieldInput}>
                <textarea
                  value={formData.content}
                  onChange={(e) => {
                    setFormData({ ...formData, content: e.target.value });
                    setErrors({ ...errors, content: "" });
                  }}
                  placeholder="Enter"
                />
                {errors.content && (
                  <span className={styles.error}>{errors.content}</span>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Link URL</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  value={formData.linkUrl}
                  onChange={(e) =>
                    setFormData({ ...formData, linkUrl: e.target.value })
                  }
                  placeholder="Enter"
                />
              </div>
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Target Audience</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={targetAudienceOptions}
                  onSelect={(selectedOption) => {
                    setFormData({
                      ...formData,
                      targetAudience: selectedOption.value,
                    });
                    setErrors({ ...errors, targetAudience: "" });
                  }}
                  initialSelectedOption={formData.targetAudience}
                />
                {errors.targetAudience && (
                  <span className={styles.error}>{errors.targetAudience}</span>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Start Date</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="date"
                  value={formatDate(formData.startDate)}
                  onChange={(e) => {
                    setFormData({ ...formData, startDate: e.target.value });
                    setErrors({ ...errors, startDate: "" });
                  }}
                />
                {errors.startDate && (
                  <span className={styles.error}>{errors.startDate}</span>
                )}
              </div>
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Geographical Scope</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={geographicalScopeOptions}
                  onSelect={(selectedOption) => {
                    setFormData({
                      ...formData,
                      geographicalScope: selectedOption.value,
                    });
                    setErrors({ ...errors, geographicalScope: "" });
                  }}
                  initialSelectedOption={formData.geographicalScope}
                />
                {errors.geographicalScope && (
                  <span className={styles.error}>
                    {errors.geographicalScope}
                  </span>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>End Date</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="date"
                  value={formatDate(formData.endDate)}
                  onChange={(e) => {
                    setFormData({ ...formData, endDate: e.target.value });
                    setErrors({ ...errors, endDate: "" });
                  }}
                />
                {errors.endDate && (
                  <span className={styles.error}>{errors.endDate}</span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Language</p>
            </div>
            <div className={styles.fieldInput}>
              <SelectComponent
                options={languageOptions}
                onSelect={(selectedOption) => {
                  setFormData({
                    ...formData,
                    language: selectedOption.value,
                  });
                  setErrors({ ...errors, language: "" });
                }}
                initialSelectedOption={formData.language}
              />
              {errors.language && (
                <span className={styles.error}>{errors.language}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBanner;
