import React from "react";
import EditItem from "../../../../../components/Modals/MasterModals/EditItem";

const EditZipcode = ({ item,fetchData }) => {

    const initialFormData = {
    zipcode: item?.zipcode,
    city: item?.city,
    state: item?.state,
  };

  const apiEndpoint = "/master/updateMasterZipCode";
  const formFields = [
    { name: "zipcode", label: "Zip Code", type: "text" },
    { name: "city", label: "City Name", type: "text" },
    { name: "state", label: "State Name", type: "text" },
  ];
  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Zipcode"
      item={item}
      // uploadFieldName="icon"
      // fetchDataOptions={{
      //   languages: fetchLanguages,
      // }}
    />
  );
};

export default EditZipcode;

