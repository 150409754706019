import React from "react";
import EditItem from "../../../../../components/Modals/MasterModals/EditItem";

const EditCity = ({ item,fetchData }) => {

    const initialFormData = {
      cityId: item?.cityId,
    state: item?.state,
    city: item?.city,
    latitude: item?.latitude,
    longitude: item?.longitude,
  };

  const apiEndpoint = "/master/updateMasterCities";
  const formFields = [
    { name: "cityId", label: "City Id", type: "text" },
    { name: "city", label: "City Name", type: "text" },
    { name: "state", label: "State Name", type: "text" },
    { name: "latitude", label: "latitude", type: "text" },
    { name: "longitude", label: "longitude", type: "text" },
  ];
  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="City"
      item={item}
      // uploadFieldName="icon"
      // fetchDataOptions={{
      //   languages: fetchLanguages,
      // }}
    />
  );
};

export default EditCity;

