import React, { useState } from "react";
import styles from "./ViewFaqCategory.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeaderTwo from "../../../../../components/PageHeader/PageHeaderTwo";

function ViewFaqCategory() {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location?.state?.item;
  console.log("item" , item)

  const initialFormData = {
    name: item?.name || "",
    description: item?.description || "",
 
  };

  const [formData, setFormData] = useState(initialFormData);

 


  const handleDiscard = () => {
    navigate("/staticcontents/faq");
  };

  const breadcrumb = [
    { label: "Faq Category", path: "/staticcontents/faq" },
    { label: "View Faq Category", path: "/staticcontents/faq/viewFaqCategory" },
  ];

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={breadcrumb}
        discardLabel="Discard"
   
        onDiscardClick={handleDiscard}
        
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.tableContainer}>
           
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Category</p>
                </div>
                <div className={styles.fieldInput}>
                <input
                    type="text"
                    value={formData.name}
                  disabled
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Description</p>
                </div>
                <div className={styles.fieldInput}>
                
                     <textarea
                    type="text"
                    value={formData.description}
                 disabled
                  />
                  
                </div>
              </div>
            </div>
           
           
            <div className={styles.footer}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewFaqCategory;


