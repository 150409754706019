import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "../Campaign/CampaignFilter.module.css";
import SelectComponent from "../../../components/SelectComponent";
import { userRequest } from "../../../requestMethod";

const TejPointsFilter = ({ isModalOpen, handleOk, handleCancel }) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [stateOptions, setStateOptions] = useState([
    { label: "-", value: "-" },
  ]);

  const [formData, setFormData] = useState({
    country: "",
    state: "",
    roleId: "",
  });

  const handleFilter = () => {
    handleOk(formData); // Pass formData back to parent component
  };

  const selectCountry = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      country: val.label,
      state: "", // Reset state when country changes
    }));
    fetchState(val.value); // Fetch states for the selected country
  };

  const selectState = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      state: val.label,
    }));
  };


  const handleRoleSelect = (option) => {
    setFormData((prevState) => ({
      ...prevState,
      roleId: option.value,
    }));
  };
  useEffect(() => {
    // Fetch task types from the API
    const fetchRoles = async () => {
      try {
        const response = await userRequest.get("/userRoles/getAllUserRoles");
        setRoles(response?.data?.data);
      } catch (error) {
        console.error("Error fetching task types:", error);
      }
    };
    fetchRoles();
  }, []);

  const roleOptions = roles.map((role) => ({
    label: role.roleName,
    value: role._id,
  }));

  useEffect(() => {
    fetchCountry();
  }, []);
  const fetchCountry = async () => {
    try {
      let response = await userRequest.get(`/master/getMasterCountry`);
      const countryData = response?.data?.data;
      let countryOpt = countryData?.map((item) => ({
        label: item?.country,
        value: item?._id,
      }));
      setCountryOptions(countryOpt);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchState();
  }, []);
  const fetchState = async (countryId) => {
    try {
      if (!countryId) {
        console.error("countryId is undefined");
        setStateOptions([{ label: "-", value: "-" }]); // Show dash if no country is selected
        return;
      }
      let response = await userRequest.get(
        `/master/getMasterState?countryId=${countryId}`
      );
      const stateData = response?.data?.data;
      let stateOpt = stateData?.map((item) => ({
        label: item?.state,
        value: item?._id,
      }));
      setStateOptions(stateOpt);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  return (
    <Modal
      visible={isModalOpen}
      onCancel={handleCancel}
      onOk={handleFilter}
      width={"29vw"}
      height={"100dvh"}
      className={styles.modal}
    >
      <div className={styles.modalTitle}>
        <p>Filter</p>
      </div>
      <div className={styles.contentMain}>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Country</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={countryOptions}
              onSelect={selectCountry}
              initialSelectedOption={formData.country}
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>State</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={stateOptions}
              onSelect={selectState}
              initialSelectedOption={formData.state}
            />
          </div>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Role</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={roleOptions}
              onSelect={handleRoleSelect}
              // initialSelectedOption={formData.roleId}
            />
          </div>
        </div>
        <div className={styles.modalButton}>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleFilter}>Filter</button>
        </div>
      </div>
    </Modal>
  );
};

export default TejPointsFilter;
