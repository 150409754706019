import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./style.module.css";
import viewIcon from "../../../assets/images/viewIcons.png";

const ImageZoom = ({ imageSrc }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={imageSrc} alt="View" onClick={showModal} style={{cursor:"pointer"}} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.contentMain}>
          <div className={styles.imageSection}>
            <img src={imageSrc} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageZoom;
