import React, { useEffect, useState } from "react";
import styles from "./createTejPoints.module.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../../../components/SelectComponent";
import PageHeader from "../../../../components/PageHeader/PageHeader";

function CreateTejPoints() {
  const navigate = useNavigate();
  const initialFormData = {
    country: "India",
    state: "",
    loginPoint: "",
    templePoint: "",
    referPoint: "",
    utsavPoint: "",
    bookingPoint: "",
    completeKycPoint: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [roles, setRoles] = useState(initialFormData);

  const selectCountry = (val) => {
    setFormData({
      ...formData,
      country: val,
      state: "", // Reset state when country changes
    });
  };

  const selectState = (val) => {
    setFormData({
      ...formData,
      state: val,
    });
  };

  const handleRoleSelect = (option) => {
    setFormData((prevState) => ({
      ...prevState,
      roleId: option.value,
    }));
  };

  useEffect(() => {
    // Fetch task types from the API
    const fetchRoles = async () => {
      try {
        const response = await userRequest.get("/userRoles/getAllUserRoles");
        setRoles(response?.data?.data);
      } catch (error) {
        console.error("Error fetching task types:", error);
      }
    };
    fetchRoles();
  }, []);

  const roleOptions =
    roles &&
    roles.length > 0 &&
    roles?.map((role) => ({
      label: role.roleName,
      value: role._id,
    }));

  const saveData = async () => {
    try {
      await userRequest.post("/tejPoints/createTejPoints", formData);
      setFormData(initialFormData);
      toast.success("Saved successfully!");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        const errorMessage = error.response.data.message;
        console.error("Tej Points Conflict:", errorMessage);
        toast.error(errorMessage);
      } else {
        console.error("Error saving data:", error.message);
        toast.error("Failed to save data. Please try again later.");
      }
    }
  };

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Svarna Management", path: "/svarnamanagement" },
          { label: "Create Tej Points", path: "#" },
        ]}
      />
      <div className={styles.contentContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Country</p>
            </div>
            <div className={styles.fieldInput}>
              <CountryDropdown
                value={formData.country}
                onChange={(val) => selectCountry(val)}
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>State</p>
            </div>
            <div className={styles.fieldInput}>
              <RegionDropdown
                country={formData.country}
                value={formData.state}
                onChange={(val) => selectState(val)}
              />
            </div>
          </div>
        </div>
        {/* <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>City</p>
            </div>
            <div className={styles.fieldInput}>
              <RegionDropdown
                country={formData.country}
                value={formData.state}
                onChange={(val) => selectState(val)}
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Role</p>
            </div>
            <div className={styles.fieldInput}>
              <SelectComponent
                options={roleOptions}
                onSelect={handleRoleSelect}
                // initialSelectedOption={formData.roleId}
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Devalaya</p>
            </div>
            <div className={styles.fieldInput}>
              <RegionDropdown
                country={formData.country}
                value={formData.state}
                onChange={(val) => selectState(val)}
              />
            </div>
          </div>
        </div> */}
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Login Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.loginPoint}
                onChange={(e) =>
                  setFormData({ ...formData, loginPoint: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Add Temple Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.templePoint}
                onChange={(e) =>
                  setFormData({ ...formData, templePoint: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Refer Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.referPoint}
                onChange={(e) =>
                  setFormData({ ...formData, referPoint: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Create Utsav Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.utsavPoint}
                onChange={(e) =>
                  setFormData({ ...formData, utsavPoint: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Booking Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.bookingPoint}
                onChange={(e) =>
                  setFormData({ ...formData, bookingPoint: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Complete KYC Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.completeKycPoint}
                onChange={(e) =>
                  setFormData({ ...formData, completeKycPoint: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>
        {/* <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>KYC Price</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" />
          </div>
        </div>
        <div className={styles.feildRight}>
          <div className={styles.fieldLabel}>
            <p>Final Amount</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" />
          </div>
        </div>
      </div> */}
        <div className={styles.footer}>
          <button>Discard</button>
          <button onClick={saveData}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default CreateTejPoints;
