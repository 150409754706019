import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";

const AddPanditType = ({ fetchData }) => {
  const initialFormData = {
    type: "",
  };

  const apiEndpoint = "/master/createPanditType";
  const formFields = [{ name: "type", label: "Pandit Type", type: "text" }];



  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Pandit Type"
   
    />
  );
};

export default AddPanditType;
